import axios from "axios";
import { Domain, environment } from "../constants";
import { log } from "./helper";

const trialSysId =
  environment === "PROD"
    ? "b58e6fccdba8e4505ca4bc04b99619df"
    : "477b98893702130037dc8cf6c3990eaa";
const upcomingStudy = "f05d6667dba9ab807fb03437b9961932";

const getMultipleSites = (nctId) => {
  //log(nctId, "nctid for fetching");
  return new Promise((resolve, reject) => {
    axios
      .get("/backend/study?id=" + nctId)
      .then((success) => {
        //log(success.data);
        let studies = success.data.result;
        let recrutingStudies = studies.filter(
          (study) =>
            (study.active === "true" &&
              study.promote === "true" &&
              study.status === "Recruiting") ||
            (study.active === "true" &&
              study.promote === "false" &&
              study.status === "Recruiting")
        );
        if (recrutingStudies && recrutingStudies.length > 0) {
          resolve(recrutingStudies);
        } else {
          resolve([]);
        }
      })
      .catch((fail) => {
        //log(fail, "error with getting multiple sites");
        reject([]);
      });
  });
};

const getQuestionGroup = (studySysId, studyId) => {
  return new Promise((resolve, reject) => {
    if (studySysId !== null || studySysId !== "") {
      axios
        .get("/backend/questiongroup?id=" + studySysId + "&studyId=" + studyId)
        .then((questionGroup) => {
          //log(`${questionGroup.data.result}, "got ourstudy"`);
          if (questionGroup.data && questionGroup.data.result.length > 0) {
            let activeQuestions = questionGroup.data.result.filter(
              (activeState) => activeState.active === "true"
            );
            resolve({
              questions: activeQuestions,
              isloading: true,
            });
          } else {
            //log("No pre-screening questions found");
            resolve({ questions: [], isloading: true });
          }
        })
        .catch((e) => {
          //log(`${e}, "error with getting our study"`);
          reject({
            questions: [],
            isloading: true,
          });
        });
    }
  });
};

const getPatientrialsStudies = () => {
  return new Promise((resolve, reject) => {
    axios
      .get("/backend/ourstudies")
      .then((output) => {
        if (
          output &&
          output.data &&
          output.data.result &&
          output.data.result.length > 0
        ) {
          let studiesOutput = output && output.data && output.data.result;
          let activeStudiesOutput =
            studiesOutput && studiesOutput.length > 0
              ? studiesOutput.filter(
                  (study) =>
                    study &&
                    study.promote === "true" &&
                    study &&
                    study.active === "true"
                )
              : [];
          //we are adding "path to wellness study for demo" even though it's status is not yet recruiting
          let path_to_wellness = activeStudiesOutput.filter(
            (study) => study.study_sys_id === trialSysId
          );
          resolve({
            error: false,
            output: activeStudiesOutput
              .filter((study) => study.status === "Recruiting")
              .concat(path_to_wellness),
          });
        } else {
          //log("No patientrials studies found");
          reject({
            error: true,
            output: [],
            message: "No patientrials studies found",
          });
        }
      })
      .catch((errResult) => {
        //log(`${errResult}, "errResult-cannot get patientrials studies"`);
        reject({
          error: true,
          output: [],
          message: "No patientrials studies found",
        });
      });
  });
};
const getRepeatedNctids = (trials) => {
  let obj = {},
    arr = [];
  if (trials && trials.length > 0) {
    trials.map((trial) => {
      obj[trial] = obj.hasOwnProperty(trial) ? obj[trial] + 1 : 1;
    });
    let keys = Object.keys(obj);
    if (keys && keys.length > 0) {
      keys.map((key) => {
        if (obj[key] > 1) {
          arr.push(key);
        } else {
          return [];
        }
      });
      return arr;
    }
  } else {
    return [];
  }
};

const removeStudies = (nctid, studies) => {
  let arr = [];
  studies.map((study) => {
    if (study.nct_number === nctid) {
      delete studies[study];
    } else {
      arr.push(study);
    }
  });
  return arr;
};

const only_One_of_Repeated_studies = (repeatedStudies, studies) => {
  // let arr = [];
  // let obj = {};
  // repeatedStudies.map((repeatedStudy) => {
  //   let filtered = studies.filter(
  //     (study) => study.promoted_by_sponsor === "true" ? study.promoted_by_sponsor === "true" : study.nct_number === repeatedStudy
  //   );
  //   arr.push(filtered[0]);
  //   //log(removeStudies(repeatedStudy, studies), "while removing");
  //   obj["studies"] = obj.hasOwnProperty("studies")
  //     ? obj["studies"].push(removeStudies(repeatedStudy, studies))
  //     : removeStudies(repeatedStudy, studies);
  // });

  // return [...arr, ...obj["studies"]];
  let latest = []
  repeatedStudies.map(repeatedStudy=>{
    let nct_studies = studies.filter(study=> study.nct_number === repeatedStudy)
    if(nct_studies && nct_studies.length > 0){
      let filter_central_study = nct_studies.filter(nct_study=> nct_study.central === "true")
      if(filter_central_study && filter_central_study.length > 0){
        log(filter_central_study[0],'central study with nct number')
        return latest.push(filter_central_study[0])
      }else{
        let latest_studies = nct_studies.sort(
                        (d1, d2) =>
                          new Date(d1.sys_created_on).getTime() -
                          new Date(d2.sys_created_on).getTime()
                      )
                      .reverse()
        log(latest_studies[0],'latest created study with nct number')
        return latest.push(latest_studies[0])
      }
    }else{
      log(nct_studies,'err fetching studies with nct number')
      return []
    }
  })
  return latest
};

const filterPTstudies = (studies, condition) => {
  if (environment === "PROD") {
    return new Promise((resolve, reject) => {
      if (studies && studies.length > 0) {
        let fs = studies.filter(
          (st) =>
            log(st.study_name, condition, "checking filters") +
              st.nct_number ===
              condition ||
            st.nct_number === condition.toUpperCase() ||
            st.nct_number === condition.toLowerCase() ||
            st.study_name.includes(condition) ||
            st.study_name.toUpperCase().includes(condition.toUpperCase()) ||
            st.study_name.toLowerCase().includes(condition.toLowerCase()) ||
            st.brief_title.includes(condition) ||
            st.brief_title.toUpperCase().includes(condition.toUpperCase()) ||
            st.brief_title.toLowerCase().includes(condition.toLowerCase()) ||
            st.official_title.includes(condition) ||
            st.official_title.toUpperCase().includes(condition.toUpperCase()) ||
            st.official_title.toLowerCase().includes(condition.toLowerCase()) ||
            st.brief_summary.toUpperCase().includes(condition.toUpperCase()) ||
            st.brief_summary.toLowerCase().includes(condition.toLowerCase()) ||
            st.brief_summary.includes(condition) === true
        );
        log(fs, "fs");
        let path_study = studies.filter(
          (trial) => trial.study_sys_id === upcomingStudy
        );
        if (fs && fs.length > 0) {
          //log(`${path_study}, "pathstudy"`);
          studies.splice(studies.indexOf(path_study[0]), 1);
          if (
            condition === "cancer" ||
            condition === "Cancer" ||
            condition === "CANCER"
          ) {
            //NCT04695977, NCT03288545, NCT01716806
            let cs1 = studies.filter(
              (study) => study.nct_number === "NCT04695977"
            );
            let cs2 = studies.filter(
              (study) => study.nct_number === "NCT03288545"
            );
            let cs3 = studies.filter(
              (study) => study.nct_number === "NCT01716806"
            );
            let both = [
              ...new Set([...cs1, ...cs2, ...cs3, ...studies, ...path_study]),
            ];
            //log(`${both}, "bothstudies"`);
            let combinedPTStudies = both;
            //log(`${combinedPTStudies}, "cps"`);
            //showing 3 our studies
            resolve({
              ourfilteredstudies: combinedPTStudies,
              norecords: false,
            });
          } else if (
            condition === "Hyperinsulinism (HI)" ||
            condition === "hyperinsulinism" ||
            condition === "HYPERINSULINISM"
          ) {
            let cs3 = studies.filter(
              (study) => study.nct_number === "NCT04538989"
            );
            let both = [...new Set([...cs3, ...studies, ...path_study])];
            //log(`${both}, "bothstudies"`);
            let combinedPTStudies = both;
            //log(`${combinedPTStudies}, "cps"`);
            //showing 3 our studies
            resolve({
              ourfilteredstudies: combinedPTStudies,
              norecords: false,
            });
          } else {
            let both = [...new Set([...fs, ...studies, ...path_study])];
            //log(`${both}, "bothstudies"`);
            let combinedPTStudies = both;
            //log(`${combinedPTStudies}, "cps"`);
            //showing 3 our studies
            resolve({
              ourfilteredstudies: combinedPTStudies,
              norecords: false,
            });
          }
        } else {
          //log(`${path_study}, "pathstudy"`);
          studies.splice(studies.indexOf(path_study[0]), 1);
          let both = [...new Set([...studies, ...path_study])];
          //log(`${both}, "bothstudies"`);
          let combinedPTStudies = both;
          //log(`${combinedPTStudies}, "cps"`);
          let repeated = getRepeatedNctids(
            combinedPTStudies.map((nctid) => nctid.nct_number)
          );
          if (repeated && repeated.length > 0) {
            // log(
            //   only_One_of_Repeated_studies(repeated, combinedPTStudies),
            //   "nct"
            // );
            //showing 3 our studies
            resolve({
              ourfilteredstudies: only_One_of_Repeated_studies(
                repeated,
                combinedPTStudies
              ),
              norecords: false,
            });
          } else {
            resolve({
              ourfilteredstudies: combinedPTStudies,
              norecords: false,
            });
          }
          //showing 3 our studies
          resolve({
            ourfilteredstudies: combinedPTStudies,
            norecords: false,
          });
        }
      } else {
        //log("No studies found to filter");
        reject({
          ourfilteredstudies: [],
        });
      }
    });
  } else if (environment === "DEV" || environment === "VAYUSHAKTI_DEV") {
    return new Promise((resolve, reject) => {
      if (studies && studies.length > 0) {
        let fs = studies.filter(
          (st) =>
            st.nct_number === condition ||
            st.nct_number === condition.toUpperCase() ||
            st.brief_title.includes(condition) ||
            st.brief_title.includes(condition.toUpperCase()) ||
            st.official_title.includes(condition) ||
            st.official_title.includes(condition.toUpperCase()) ||
            st.brief_summary.includes(condition.toUpperCase()) ||
            st.brief_summary.includes(condition) === true
        );
        //log(`${fs}, "fs"`);
        if (fs && fs.length > 0) {
          //filter path to well ness study
          //log(`${fs}, "fs"`);
          //change it back to study.sys_id once field is added
          let path_study = studies.filter(
            (trial) => trial.study_sys_id === trialSysId
          );
          //log(`${path_study}, "pathstudy"`);
          let both = [...new Set(fs.concat(path_study))];
          //log(both, "bothstudies");
          let combinedPTStudies =
            both && both.length > 0
              ? both.length <= 3
                ? both
                : [both[0], both[1], both[2]]
              : [];
          //log(`${combinedPTStudies}, "cps"`);
          let repeated = getRepeatedNctids(
            combinedPTStudies.map((nctid) => nctid.nct_number)
          );
          if (repeated && repeated.length > 0) {
            log(
              only_One_of_Repeated_studies(repeated, fs),
              "nct"
            );
            //showing 3 our studies
            resolve({
              ourfilteredstudies: only_One_of_Repeated_studies(
                repeated,
                fs
              ),
              norecords: false,
            });
          } else {
            resolve({
              ourfilteredstudies: fs,
              norecords: false,
            });
          }
        } else {
          resolve({
            ourfilteredstudies: studies.filter(
              (trial) => trial.study_sys_id === trialSysId
            ),
            norecords: false,
          });
        }
      } else {
        //log("No studies found to filter");
        reject({
          ourfilteredstudies: [],
        });
      }
    });
  }
};

const getTrialId = (studyId) => {
  return new Promise((resolve, reject) => {
    if (studyId === undefined || studyId === null) {
      reject({ isLoading: true, trial: null, showRequirements: false });
    }
    // else if (studyId.startsWith("NCT")) {
    //   axios
    //     .get( "/backend/trial?nctid=" + studyId + "&ptr=ts")
    //     .then((study) => {
    //       log(`${study.data.study.data.trial}, "got ts study"`);
    //       resolve({ trial: study.data.study.data.trial, isLoading: true });
    //     })
    //     .catch((errStudy) => {
    //       log(`${errStudy}, "errStudy- error getting study with nctid"`);
    //       resolve({ trial: null, isLoading: true });
    //     });
    // }
    else if (studyId.startsWith("NCT") || studyId.startsWith("PTGB")) {
      getMultipleSites(studyId)
        .then((gotStudies) => {
          //log(gotStudies, "multiple sites");
          if (gotStudies && gotStudies.length > 0) {
            resolve({
              trial: gotStudies,
              isLoading: true,
              partner: "patientrials",
            });
          } else {
            axios
              .get("/backend/trial?id=" + studyId + "&ptr=cs")
              .then((ctstudy) => {
                //log(`${ctstudy && ctstudy.data}, "got ct study"`);
                let clinicalTrialStudy =
                  ctstudy && ctstudy.data && ctstudy.data.output;
                if (clinicalTrialStudy && clinicalTrialStudy.length > 0) {
                  resolve({
                    trial: clinicalTrialStudy[0],
                    isLoading: true,
                    partner: "clinical trials",
                  });
                } else {
                  resolve({
                    trial: null,
                    isLoading: true,
                    partner: "clinical trials",
                  });
                }
              })
              .catch((errCtstudies) => {
                //log(`${errCtstudies}, "clinical trial study not found"`);
                resolve({
                  trial: null,
                  partner: "clinical trials",
                  isLoading: true,
                });
              });
          }
        })
        .catch((errGotStudies) => {
          //log(errGotStudies, "error getting pt studies with nctid");
          axios
            .get("/backend/trial?id=" + studyId + "&ptr=cs")
            .then((ctstudy) => {
              //log(`${ctstudy && ctstudy.data}, "got ct study"`);
              let clinicalTrialStudy =
                ctstudy && ctstudy.data && ctstudy.data.output;
              if (clinicalTrialStudy && clinicalTrialStudy.length > 0) {
                resolve({
                  trial: clinicalTrialStudy[0],
                  isLoading: true,
                  partner: "clinical trials",
                });
              } else {
                resolve({
                  trial: null,
                  isLoading: true,
                  partner: "clinical trials",
                });
              }
            })
            .catch((errCtstudies) => {
              //log(`${errCtstudies}, "clinical trial study not found"`);
              resolve({
                trial: null,
                partner: "patientrials",
                isLoading: true,
              });
            });
        });
    } else if (studyId.startsWith("STU")) {
      //log("starts with STU");
      axios
        .get("/backend/trial?id=" + studyId + "&ptr=ps")
        .then((ptstudy) => {
          //log(ptstudy.data, "got ps study");
          resolve({
            trial:
              ptstudy &&
              ptstudy.data &&
              ptstudy.data.study &&
              ptstudy.data.study.result &&
              ptstudy.data.study.result[0],
            isLoading: true,
            partner: "patientrials",
          });
        })
        .catch((errStudy) => {
          //log(`${errStudy}, "errStudy- error getting study with studyid"`);
          resolve({ trial: null, isLoading: true, partner: "patientrials" });
        });
    } else {
      resolve({ trial: null, isLoading: true, partner: "" });
    }
  });
};

const getSuggestions = (condition) => {
  let stringTypeSuggestions = [];
  return new Promise((resolve, reject) => {
    if (condition && condition.length > 0) {
      axios
        .post("/backend/suggestions", { condition: condition })
        .then((suggestions) => {
          //log(suggestions && suggestions.data && suggestions.data.output,'suggestions')
          let suggestions_for_condition =
            suggestions.data.output && suggestions.data.output.suggestions;
          if (
            suggestions_for_condition &&
            suggestions_for_condition.length > 0
          ) {
            //log(suggestions_for_condition,'suggestions')
            suggestions_for_condition.map((suggestion) =>
              stringTypeSuggestions.push(suggestion[0])
            );
            log(stringTypeSuggestions, "after fetching suggestions");
            resolve(stringTypeSuggestions);
          } else {
            resolve([]);
          }
        })
        .catch((errSuggestions) => {
          log(errSuggestions, "error with fetching suggestions");
          reject([]);
        });
    } else {
      reject([]);
    }
  });
};

const getStudyFromStudyNumber = (number) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`/backend/preview?number=${number}`)
      .then((gotStudy) => {
        log(gotStudy, "got study with number");
        resolve(gotStudy && gotStudy.data && gotStudy.data.output);
      })
      .catch((errStudy) => {
        log(errStudy, "err while fetching study with number");
        reject([]);
      });
  });
};

const fetchHealthSystems = (number, search) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`/backend/emr/healthSystems?number=${number}&search=${search}`)
      .then((gotSystems) => {
        log(gotSystems, "got health systems");
        resolve(gotSystems.data.output);
      })
      .catch((errSystems) => {
        log(errSystems, "err with health systems");
        reject(null);
      });
  });
};

const fetchRandomHealthSystems = (randomID, city) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`/backend/emr/random/healthSystems?user=${randomID}&city=${city}`)
      .then((gotSystems) => {
        log(gotSystems, "got health systems");
        resolve(gotSystems.data.output);
      })
      .catch((errSystems) => {
        log(errSystems, "err with health systems");
        reject(null);
      });
  });
};

const fetchRecords = (number) => {
  return new Promise((resolve, reject) => {
    axios
      .get("/backend/emr/records?number=" + number)
      .then((success) => {
        log(success.data, "got records");
        resolve(success.data.output);
      })
      .catch((failure) => {
        log(failure, "err with records");
        reject([]);
      });
  });
};

const fetchRandomRecords = (number) => {
  return new Promise((resolve, reject) => {
    axios
      .get("/backend/emr/random/records?number=" + number)
      .then((success) => {
        log(success.data, "got records");
        resolve(success.data.output);
      })
      .catch((failure) => {
        log(failure, "err with records");
        reject([]);
      });
  });
};

const createFile = (ptId, records, dob, gender, nctid) => {
  return new Promise((resolve, reject) => {
    axios
      .post("/backend/emr/createFile", {
        prospect: ptId,
        records: records,
        dob: dob,
        nctid: nctid,
        gender: gender,
      })
      .then((success) => {
        log(success, "file created successfully");
        resolve(success.data);
      })
      .catch((failure) => {
        log(failure, "err with file");
        reject(null);
      });
  });
};

const uploadAttachment = (prospect, file, file_name, file_type) => {
  let fileHeaders = {
    "Content-Type": file_type,
  };
  return new Promise((resolve, reject) => {
    axios
      .post(
        `/backend/upload-record?prospect=${prospect}&file_name=${file_name}`,
        file,
        { headers: fileHeaders }
      )
      .then((success) => {
        log(success, "file submitted successfully");
        resolve(success.data);
      })
      .catch((failure) => {
        log(failure, "err with submitting file");
        reject(null);
      });
  });
};

const fetchVirtualAppointments = (number, apptId) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`/backend/virtual-appts?number=${number}&apptId=${apptId}`)
      .then((gotAppts) => {
        log(gotAppts, "got virtual appts");
        resolve(gotAppts.data.output);
      })
      .catch((errAppts) => {
        log(errAppts, "err with appts");
        reject([]);
      });
  });
};

const getConferenceStatus = (link) => {
  return new Promise((resolve, reject) => {
    axios
      .post("/backend/checkStatus", {
        link: link,
      })
      .then((gotStatus) => {
        log(gotStatus.data.output, "got meeting status");
        resolve( gotStatus.data.output );
      })
      .catch((errGotStatus) => {
        log(errGotStatus, "err fetching meeting status");
        reject(null);
      });
  });
};

const checkEMRUser = (id) =>{
  return new Promise((resolve , reject) =>{
    axios.get(`/backend/emr/checkuser?user=${id}`).then(status=>{
      log(status.data, 'got status');
      resolve(status.data.output)
    }).catch(errStatus=>{
      log(errStatus , 'err status');
      reject(null)
    })
  })
}

const checkPatientEMRUser = (id) =>{
  return new Promise((resolve , reject) =>{
    axios.get(`/backend/emr/patient-checkuser?user=${id}`).then(status=>{
      log(status.data, 'got status');
      resolve(status.data.output)
    }).catch(errStatus=>{
      log(errStatus , 'err status');
      reject(null)
    })
  })
}

const prospectStatus = (number) =>{
  return new Promise((resolve , reject)=>{
    axios.get(`/backend/prospect-status?number=${number}`).then(status=>{
      log(status.data, 'got prospect status');
      resolve(status.data.output)
    }).catch(errStatus=>{
      log(errStatus , 'err status');
      reject(null)
    })
  })
}

const updateProspectStatus = (status , prospect , sysId) =>{
  return new Promise((resolve , reject)=>{
    axios.post(`/backend/prospect-status`,{
      status : status,
      prospect : prospect,
      sysId : sysId,
    }).then(update=>{
      log(update , 'updated');
      resolve(update)
    }).catch(errUpdate=>{
      log(errUpdate , 'err while updating');
      reject(null)
    })
  })
}

const documentUpload = (file , filename) =>{
  return new Promise((resolve , reject)=>{
    axios.post(`/backend/document-upload?filename=${filename}`, file).then(success=>{
      log(success.data.output , 'updated');
      resolve(success.data.output)
    }).catch(failure=>{
      log(failure , 'err while submitting file');
      reject(null)
    })
  })
}

const getPatientWithEmail = (input) =>{
  log(input,'ip')
  return new Promise((resolve , reject)=>{
    axios.post(`/backend/ptCheck`,input).then(ptFound=>{
      log(ptFound , 'pt found');
      resolve(ptFound)
    }).catch(errPtFound=>{
      log(errPtFound , 'err while pt fetch');
      reject(null)
    })
  })
}

export {
  getPatientrialsStudies,
  filterPTstudies,
  getTrialId,
  getQuestionGroup,
  getMultipleSites,
  getSuggestions,
  getStudyFromStudyNumber,
  fetchHealthSystems,
  fetchRecords,
  createFile,
  uploadAttachment,
  fetchVirtualAppointments,
  getConferenceStatus,
  fetchRandomHealthSystems,
  checkEMRUser,
  fetchRandomRecords,
  prospectStatus,
  updateProspectStatus,
  documentUpload,
  checkPatientEMRUser,
  getPatientWithEmail
};
