import React from "react";
import { DataNotFound, RecruitmentSteps, ScreenFlow } from "../../components";
import {
  template4identifier,
  template5nct,
  template5gender,
  template4site,
  template4status,
  template5minimum,
  template5maximum,
  greenDot,
  template6main,
  template6sub,
  template5save,
} from "../theme/icons";
import {
  enrollLink,
  getInclusionCriteria,
  getExclusionCriteria,
} from "../../helpers/helper";

const Template6 = (props) => {
  const { trial, searchTerm } = props;

  const flowarray = (studyNumber) => {
    return [
      { title: "Home", link: "/" },
      { title: "Find Trials", link: "/#find_trial" },
      {
        title: "Search Results",
        link: `/results?q=${studyNumber}&in=UNITED%20STATES`,
      },
      {
        title: "Trial Details",
        link: `/preview/${studyNumber}?template=${searchTerm}`,
      },
    ];
  };

  const studyIdentifiers = () => {
    return [
      {
        id: 1,
        logo: template4identifier,
        title: (trial && trial.nct_number) || "None",
        subtitle: "Clinical Study Identifier",
        bg: "#76D6EE",
      },
      {
        id: 2,
        logo: template4site,
        title: (trial && trial.site_name) || "None",
        subtitle: "Site Name",
        bg: "#C9AFD2",
      },
      {
        id: 3,
        logo: template4status,
        title: (trial && trial.status) || "None",
        subtitle: "Trial Status",
        bg: "#8CD2A9",
      },
    ];
  };

  const studyQualifications1 = () => {
    return [
      {
        id: 1,
        logo: template5gender,
        title: "Gender",
        subtitle: (trial && trial.sex) || "None",
        bg: "#C9AFD2",
      },
      {
        id: 2,
        logo: template5nct,
        title: "Study ID",
        subtitle: (trial && trial.snow_study_number) || "None",
        bg: "#F2D888",
      },
    ];
  };
  const studyQualifications2 = () => {
    return [
      {
        id: 3,
        logo: template5maximum,
        title: "Maximum Age",
        subtitle: (trial && trial.maximum_age) || "None",
        bg: "#F2D888",
      },
      {
        id: 4,
        logo: template5minimum,
        title: "Minimum Age",
        subtitle: (trial && trial.minimum_age) || "None",
        bg: "#E99781",
      },
    ];
  };

  return (
    <div>
      {trial === null ? (
        <DataNotFound message="Selected template not found" />
      ) : (
        <div>
          <div>
            <div className="container">
              <RecruitmentSteps step={"Review Trials"} />
              <div className="d-flex align-items-center justify-content-start">
                <ScreenFlow
                  flow={flowarray(trial && trial.snow_study_number)}
                />
              </div>
              <div className="d-flex align-items-center justify-content-center flex-column py-5 my-0">
                <p className="t5_title mb-0 col-5 ml-4">
                  {(trial && trial.brief_title) || "None"}
                </p>
                {/* <h6 className="mb-0">Location Details</h6>
                    <p className="my-2 mb-0 site">
                      {(trial && trial.site_name) || "None"}
                    </p>
                    <p className="mb-0">
                      {(trial && trial.street_address) || "None"},{" "}
                      {(trial && trial.city) || "None"}
                    </p>
                    <p className="mb-0">
                      {(trial && trial.state) || "None"},{" "}
                      {(trial && trial.country) || "None"},{" "}
                      {(trial && trial.pin_code) || "None"}
                    </p> */}
                <button
                  onClick={() =>{
                    if(trial && trial.active === "true" && trial.promote === "true"){
                      enrollLink(trial && trial.snow_study_number)
                    }
                  }
                  }
                  disabled = {(trial && trial.active === "true" && trial.promote === "true") ? false : true}
                  className="upcoming_signup btn btn-lg my-3"
                >
                  Sign Up
                </button>
              </div>
            </div>
            <div
              style={{ backgroundColor: "#F6F8FD" }}
              className="container-fluid py-5"
            >
              <div className="container">
                <div className="col-lg-10 text-left px-0">
                  <div className="row text-left template2_title">
                    <div className="d-flex align-items-center justify-content-center col-xs-12">
                      <img
                        className="mt-1"
                        style={{ height: 17, width: 17 }}
                        src={greenDot}
                        alt="dot"
                      ></img>
                      <p className="mb-0 pl-2 pr-5 template2_study_number">
                        {(trial && trial.snow_study_number) || "None"}
                      </p>
                    </div>
                    <div className="d-flex align-items-center justify-content-center col-xs-12">
                      <p className="mb-0 mx-lg-5 mx-md-5 mx-sm-5">
                        {(trial && trial.study_name) || "None"}
                      </p>
                      <img src={template5save} alt="savetag"></img>
                    </div>
                  </div>
                </div>
                <div className="container">
                  <div className="row mx-0 px-0 my-2">
                    {studyIdentifiers().map((identifier) => (
                      <div className="col-lg-4 my-2 d-flex align-items-center justify-content-center">
                        <div>
                          <div
                            style={{
                              height: 80,
                              width: 80,
                              backgroundColor: identifier.bg,
                              borderRadius: 40,
                            }}
                            className="text-center pt-3"
                          >
                            <img
                              src={identifier.logo}
                              alt="template5 logo"
                            ></img>
                          </div>
                          <div className="text-left mt-4">
                            <p className="template4_subtitle mb-0">
                              {identifier.subtitle}
                            </p>
                            <h6 className="template4_title mb-0">
                              {identifier.title}
                            </h6>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="container">
              <div>
                <div className="upcoming_purpose my-3">
                  <h2 className="my-3">Purpose</h2>
                  <p style={{ fontWeight: "normal" }} className="mb-0">
                    {(trial && trial.brief_summary) || "None"}
                  </p>
                </div>
                <br />
                <div className="upcoming_purpose my-3">
                  <h2 className="my-3">Description</h2>
                  <p style={{ fontWeight: "normal" }} className="mb-0">
                    {(trial && trial.detailed_description) || "None"}
                  </p>
                </div>
              </div>
            </div>
            <div
              style={{ backgroundColor: "#F6F8FD" }}
              className="container-fluid"
            >
              <div className="container px-0">
                <div className="row py-3 py-5 mx-0">
                  <div className="col-lg-6">
                    <img
                      src={template6main}
                      alt="template6main"
                      className="t6_main_logo"
                    ></img>
                    <img
                      src={template6sub}
                      alt="template6main"
                      className="t6_sub_logo"
                    ></img>
                  </div>
                  <div className="col-lg-6">
                    <div className="upcoming_qualify">
                      <h3 className="my-2">Who Qualifies?</h3>
                    </div>
                    <div className="t5_container flex-xs-column mx-0 px-0 col-lg-12 py-3">
                        {studyQualifications1().map(qualification1=>
                          <div style={{ backgroundColor: "#FFFFFF" }} className="col-lg-5 my-2 mr-4 p-2 py-4 t5_identifier">
                              <img style={{ height: 40, width:40 }} src={qualification1.logo} className="mb-3" alt={"icon"}></img>
                              <h6 className="mb-0 template4_title">{qualification1.title}</h6>
                              <p className="mb-0 template4_subtitle">{qualification1.subtitle}</p>
                          </div>
                        )}
                    </div>
                    <div className="t5_container flex-xs-column mx-0 px-0 col-lg-12 py-3">
                        {studyQualifications2().map(qualification1=>
                          <div style={{ backgroundColor: "#FFFFFF" }} className="col-lg-5 my-2 mr-4 p-2 py-4 t5_identifier">
                              <img style={{ height: 40, width:40 }} src={qualification1.logo} className="mb-3" alt={"icon"}></img>
                              <h6 className="mb-0 template4_title">{qualification1.title}</h6>
                              <p className="mb-0 template4_subtitle">{qualification1.subtitle}</p>
                          </div>
                        )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="container">
              <div className="col-lg-12 my-5">
                <div className="upcoming_purpose">
                  <h3 className="my-2 upcoming_criteria">Criteria</h3>
                  <h2 className="my-3">Inclusion Criteria</h2>
                  <p style={{ fontWeight: "normal" }} className="mb-0">
                    {getInclusionCriteria(trial && trial.eligibility)}
                  </p>
                </div>
                <div className="my-2 upcoming_purpose">
                  <h2 className="my-3">Exclusion Criteria</h2>
                  <p style={{ fontWeight: "normal" }} className="mb-0">
                    {getExclusionCriteria(trial && trial.eligibility)}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Template6;
