import React from "react";
import "../../App.css";
import "../../bootstrap.css";
import { environment } from "../../constants";
import {
  greenDot,
  viewMore,
  logo,
  antidoteFaviLogo,
  trialscopeFaviLogo,
  pcriLogo,
  i_trial_logo,
} from "../../features/theme/icons";
import { log } from "../../helpers/helper";

const promoStudies = [
  { nctid: "NCT01716806", logo: trialscopeFaviLogo, text: "trialscope" },
  { nctid: "NCT04232280", logo: trialscopeFaviLogo, text: "trialscope" },
  {
    nctid: "NCT03095066",
    logo: trialscopeFaviLogo,
    text: "trialscope",
    width: 110,
  },
  { nctid: "NCT03043313", logo: trialscopeFaviLogo, text: "trialscope" },
  { nctid: "NCT03896945", logo: trialscopeFaviLogo, text: "trialscope" },
  { nctid: "NCT04528719", logo: trialscopeFaviLogo, text: "trialscope" },
  { nctid: "NCT04860297", logo: trialscopeFaviLogo, text: "trialscope" },
  { nctid: "NCT04538989", logo: trialscopeFaviLogo, text: "trialscope" },
  { nctid: "NCT03288545", logo: trialscopeFaviLogo, text: "trialscope" },
  { nctid: "NCT04144348", logo: trialscopeFaviLogo, text: "trialscope" },
  { nctid: "NCT04813796", logo: trialscopeFaviLogo, text: "trialscope" },
  { nctid: "NCT04956575", logo: trialscopeFaviLogo, text: "trialscope" },
  { nctid: "NCT04628481", logo: trialscopeFaviLogo, text: "PCRI" },
  { nctid: "NCT04695977", logo: trialscopeFaviLogo, text: "trialscope" },
  {
    nctid: "NCT04053634",
    logo: antidoteFaviLogo,
    text: "antidote.me",
    width: 120,
  },
  {
    nctid: "NCT04133909",
    logo: antidoteFaviLogo,
    text: "antidote.me",
    width: 120,
  },
];

const getPartnerLogo = (nctid, vendor, study_sponsor_url) => {
  if (
    (vendor && vendor.length) === 0 ||
    vendor === "" ||
    vendor === " " ||
    vendor === "undefined" ||
    vendor === null
  ) {
    let study = promoStudies.filter((promostudy) => promostudy.nctid === nctid);
    //log(study, 'study logo')
    if (study && study.length > 0) {
      return study[0].logo;
    } else {
      if (study_sponsor_url && study_sponsor_url.length > 0) {
        return study_sponsor_url;
      } else {
        return logo;
      }
    }
  } else if (
    vendor === "trialscope" ||
    vendor === "Trialscope" ||
    vendor === "TRIALSCOPE"
  ) {
    return trialscopeFaviLogo;
  } else if (
    vendor === "antidote" ||
    vendor === "Antidote" ||
    vendor === "ANTIDOTE"
  ) {
    return antidoteFaviLogo;
  } else {
    let study = promoStudies.filter((promostudy) => promostudy.nctid === nctid);
    if (study && study.length > 0) {
      return study[0].logo;
    } else {
      return logo;
    }
  }
};
const getPartnerText = (nctid) => {
  let study = promoStudies.filter((promostudy) => promostudy.nctid === nctid);
  if (study && study.length > 0) {
    return study[0].text;
  } else {
    return "trials.me";
  }
};
const getWidth = (nctid, vendor, study_sponsor_url) => {
  if (nctid === "NCT04053634" || nctid === "NCT04133909") {
    return "ptrlogo favlogowidth";
  } else if (study_sponsor_url && study_sponsor_url.length > 0) {
    let study = promoStudies.filter((promostudy) => promostudy.nctid === nctid);
    if (study && study.length > 0) {
      return "ptrlogo";
    } else if (vendor && vendor.length > 0) {
      return "ptrlogo";
    } else {
      return "sponsorlogo";
    }
  } else {
    return "ptrlogo";
  }
};

const PromoClick = (link) => {
  log(`${link}, "link"`);
  window.open(link, "_self");
};

const PTCard = (props) => {
  const {
    condition,
    snow_study_number,
    nctNumber,
    vendor,
    staticLink,
    title,
    description,
    onPTCardClick,
    study_sponsor_url,
    sysId,
    site_name,
    study_name,
    status,
    sex,
    maximum_age,
    minimum_age,
  } = props;

  const onCard = () => {
    log(staticLink, "static link");
    window.sessionStorage.setItem("snow_study_number", snow_study_number);
    if (environment === "PROD" && snow_study_number === "STU01009") {
      // PromoClick("/upcoming-research");
      onPTCardClick(nctNumber);
    } else if (environment === "DEV" && snow_study_number === "STU0001002") {
      // PromoClick("/upcoming-research");
      onPTCardClick(nctNumber);
    } else if (staticLink && staticLink.length > 0) {
      //window.location.href = "/study/" + nctNumber;
      PromoClick(staticLink);
    } else {
      if (nctNumber && nctNumber.length > 0) {
        onPTCardClick(nctNumber);
      } else {
        onPTCardClick(snow_study_number);
      }
    }
  };

  const getTrialAge = (min, max) => {
    if (min && min.length === 0 && max && max.length === 0) {
      return "None";
    } else if (min === "" && max === "") {
      return "None";
    } else if (min === " " && max === " ") {
      return "None";
    } else if (min === undefined && max === undefined) {
      return "None";
    } else if (min === null && max === null) {
      return "None";
    } else if (
      min === " " ||
      min === "" ||
      min === undefined ||
      min === null ||
      (min && min.length) === 0
    ) {
      return `below ${maximum_age} years`;
    } else if (
      max === " " ||
      max === "" ||
      max === undefined ||
      max === null ||
      (max && max.length) === 0
    ) {
      return `above ${minimum_age} years`;
    } else {
      return `${minimum_age} - ${maximum_age} years`;
    }
  };
  return (
    <div
      onClick={() => onCard()}
      style={{
        // filter: "drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.15))",
        cursor: "pointer",
        backgroundColor: '#EFF4F7'
      }}
      className="card border pb-3 pl-3 mx-0 my-3 mb-4"
    >
      <div className="d-flex align-items-start justify-content-between">
        <div className="pt-4 d-flex align-items-center justify-content-center">
          {/* <img className="mx-2" src={greenDot} alt="greendot"></img> */}
          {/* <span className="mx-1">{phase}</span> */}
          {/* <span className="mx-1">{"|"}</span> */}
          <span className="mb-0 ml-3">
            {study_name && study_name.length > 60
              ? study_name.slice(0, 60) + "..."
              : study_name}
          </span>
          {/* {condition && condition.length > 0 ? (
            condition && condition.length > 40 ? (
              <span>{condition.slice(0, 40) + "..."}</span>
            ) : (
              <span>{condition}</span>
            )
          ) : (
            <span className="mx-1">{"None"}</span>
          )} */}
        </div>
        <div>
          {/* <div className="m-0 p-0 text-right ptr_logo">
            <span className="pt_advertise_span mx-1">advertised</span>
            <img
              className="pt_advertise_img"
              src={"https://logodix.com/logo/45540.jpg"}
              alt="yellow star"
            ></img>
          </div> */}
          <div className="ml-4 py-2 px-2">
            <img
              // src={getPartnerLogo(nctNumber, vendor, study_sponsor_url)}
              src={i_trial_logo}
              alt="PTfavicon"
              className="ml-3"
            ></img>
            <div>
                <h6 className="mb-0 mt-1" style={{color: "#E4013F", fontSize: 12, fontWeight : 'bold'}}>{nctNumber && nctNumber.length > 0 ? nctNumber : "None"}</h6>
            </div>
            {/* <div className="partnertext">{getPartnerText(nctNumber)}</div> */}
            {/* <img src={bookmark_inactive} alt="select tag"></img> */}
          </div>
        </div>
      </div>
      <div className="row card_description">
        <div className="col-sm-12 col-md-8 col-lg-8">
          <h3 className="my-3">{title}</h3>
          <p className="my-3">{description}</p>
        </div>
        <div className="card_location col-sm-11 col-md-3 col-lg-3">
          {/* <div className="card_right_border border-bottom mb-1">
            <h4>LOCATION</h4>
            <h5>
              <span>{locationCount}</span> locations near the US, zip code{" "}
              <span>{locationZipcode}</span>
            </h5>
          </div> */}
          <div className="card_right_border border-bottom mb-1 d-flex align-items-center">
            <div style={{width: 150}} className="text-left">
                <h4>Identifier</h4>
                <h5>
                  {nctNumber && nctNumber.length > 0
                    ? nctNumber
                    : snow_study_number}
                </h5>
            </div>
            <div className="text-left">
                <h4>Gender</h4>
                <h5>
                  {sex && sex.length > 0 ? sex : "None"}
                </h5>
            </div>
          </div>
          <div className="card_right_border border-bottom mb-1 d-flex align-items-center">
            <div style={{width: 150}} className="text-left">
                <h4>Site Name</h4>
                <h5>
                  {site_name && site_name.length > 30
                    ? site_name.slice(0, 30) + "..."
                    : site_name}
                </h5>
            </div>
            <div className="text-left">
                <h4>Conditions</h4>
                <h5>
                  {condition && condition.length > 0
                  ? condition && condition.length > 20
                    ? condition.slice(0, 20) + "..."
                    : condition
                  : "None"}
                </h5>
            </div>
          </div>
          <div className="mb-1 d-flex align-items-center">
            <div style={{width: 150}} className="text-left">
                <h4>Status</h4>
                <h5>{status}</h5>
            </div>
            <div className="text-left">
                <h4>Age</h4>
                <h5>
                {getTrialAge(minimum_age, maximum_age)}
                </h5>
            </div>
          </div>
        </div>
        <div className="col-12">
          <div className="d-flex align-items-center justify-content-end">
              <h6 style={{fontFamily: 'NunitoBold'}} className="view_text mb-0">VIEW DETAILS</h6>
          </div>
        </div>
      </div>
      {/* <div className="ptcard_bottom row d-flex align-items-center justify-content-start">
        <div className="mb-0 col-lg-3 col-xs-12 d-flex align-items-center justify-content-start">
          <p className="mb-0">Gender: </p>
          <span className="mb-1 mx-2">
            {sex && sex.length > 0 ? sex : "None"}
          </span>
        </div>
        <div className="mb-0 col-lg-5 col-xs-12 d-flex align-items-center justify-content-start">
          <p className="mb-0">Conditions: </p>
          <span className="mb-1 mx-2">
            {condition && condition.length > 0
              ? condition && condition.length > 20
                ? condition.slice(0, 20) + "..."
                : condition
              : "None"}
          </span>
        </div>
        <div className="mb-0 col-lg-4 col-xs-12 d-flex align-items-center justify-content-start">
          <p className="mb-0">Age: </p>
          <span className="mb-1 mx-2">
            {getTrialAge(minimum_age, maximum_age)}
          </span>
        </div>
      </div> */}
    </div>
  );
};

export default PTCard;
