import React, { useEffect, useState } from "react";
import "../../../App.css";
import { RecruitmentSteps, ScreenFlow, Map } from "../../../components";
import { getPatientrialsStudies } from "../../../helpers";
import {
  upcomingStudyIcon,
  enroll_gender,
  enroll_age,
  enroll_rank,
} from "../../../features/theme/icons";
import { blogArticles } from "../../blogs/articles";
import { environment, Google_Map_Key } from "../../../constants";
import { log } from "../../../helpers/helper";
import { Helmet } from "react-helmet";

const [firstBlog, secondBlog, ...remaining] = blogArticles;

const PtEnrollScreen = (props) => {
  const { trial } = props;
  const [activeTrials, setActiveTrials] = useState([]);
  useEffect(() => {
    getPatientrialsStudies()
      .then((studies) => {
        log(`${studies}, "studies"`);
        if (studies && studies.output && studies.output.length > 0) {
          setActiveTrials(studies.output);
        } else {
          setActiveTrials([]);
        }
      })
      .catch((e) => {
        log(`${e}, "e with study"`);
        setActiveTrials([]);
      });
  }, []);

  const flowarray = [
    { title: "Home", link: "/" },
    { title: "Find Trials", link: "/#find_trial" },
    {
      title: "Search Results",
      link: `/results?q=${"upcoming studies"}&in=${"UNITED%20STATES"}`,
    },
    {
      title: "Trial Details",
      link: "/upcoming-research",
    },
  ];

  const trialSuggestionClick = (snow_study_number, staticLink) => {
    if (environment === "PROD" && snow_study_number === "STU01009") {
      window.open("/upcoming-research", "_self");
    } else if (environment === "DEV" && snow_study_number === "STU0001002") {
      window.open("/upcoming-research", "_self");
    } else if (staticLink && staticLink.length > 0) {
      window.open(staticLink, "_self");
    } else {
      window.open(`/trialdetails/${snow_study_number}`, "_self");
    }
  };

  const getRandomNumber = (arr) => {
    log(`${Math.floor(Math.random() * arr.length)},'random'`);
    return Math.floor(Math.random() * arr.length);
  };
  const enrollLink = () => {
    window.location.href = `/enroll/${
      environment === "PROD" ? "STU01009" : "STU0001002"
    }`;
  };
  const TrialDetails = () => {
    return (
      <div className="p-0">
        <div className="container d-flex align-items-center justify-content-start">
          <ScreenFlow flow={flowarray} />
        </div>
        {/* paid clinical trials */}
        <div
          style={{
            background:
              "linear-gradient(180deg, rgba(196, 196, 196, 0) 12.81%, rgba(118, 214, 238, 0.2) 73.23%)",
          }}
        >
          <div className="container">
            <div className="row p-0">
              <div className="col-lg-6 p-0 d-flex align-items-center justify-content-start">
                <img
                  className="upcoming_study_image img-responsive"
                  src={upcomingStudyIcon}
                  alt="study_image"
                ></img>
              </div>
              <div className="upcoming_paid col-lg-6 p-0 my-auto">
                <div className="d-block align-items-start justify-content-center mx-auto text-center">
                  <h3 className="col-lg-8 mx-auto text-center my-3">
                    {/* Paid Clinical Trials and Studies */}
                    Definitive cure for Visceral Leishmaniasis
                  </h3>
                  <p className="my-3">
                    Trial Status :{" "}
                    <span style={{ color: "#12BBE5" }}>Recruiting</span>
                  </p>
                  {/* <button
                    onClick={() => enrollLink()}
                    className="upcoming_signup btn btn-lg my-3"
                  >
                    SIGN UP FOR THE STUDY
                  </button> */}
                  <a href={`#map`}>
                    <p
                      style={{ fontSize: 15, color: "#C86007" }}
                      className="mb-0 mt-1"
                    >
                      View all locations to enroll
                    </p>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-9 p-0">
              <div className="upcoming_purpose my-3">
                <h2 className="my-3">Purpose</h2>
                <p className="mb-0">
                  {" "}
                  {/* Do want to get involved in clinical research? PatienTrials
                  will match the right clinical trial for you. Please make sure
                  to enter the right mobile number to receive OTP to login.
                  Download PatienTrials mobile app to stay in touch with your
                  study staff and auto wellness agent. */}
                  The purpose of this study is to determine whether a single
                  bolus of dose of Amphoterin B lipid emulsion (Amphomul) is as
                  efficacious and safe compared to a single dose Liposomal
                  Amphotericin B in treating patients with Indian Visceral
                  Leishmaniasis (Kala Azar).{" "}
                </p>
              </div>
              <br />
              <div className="upcoming_purpose my-3">
                <h2 className="my-3">Description</h2>
                <p className="mb-0">
                  {/* The first opioid case to go trial has just concluded in
                  Oklahoma with J&J as the sole defendant. This case is
                  important for all life science companies because of its
                  approach to the concept of public nuisance and the fact that
                  liability may now extend past the final manufacturer to other
                  companies in the product supply chain (e.g., active
                  pharmaceutical ingredient manufacturers). This article
                  provides a detailed review of the case. */}
                  Visceral Leishmaniasis, which is progressive and fatal if not
                  treated, is an insidious, chronic disease that is
                  characterized by irregular fever, anorexia, weight loss,
                  cough, gross enlargement of the spleen and liver, mild anemia
                  and emaciation. This may be preceded by rigors and vomiting.
                  If untreated, Kala-azar, which is the most severe form of
                  Leishmaniasis, has a mortality rate of nearly 100%. The goal
                  of the project is to establish that a single dose of AMPHOMUL®
                  can be used to achieve a Definitive cure for Visceral
                  Leishmaniasis leading to a short course therapy. The project
                  will also seek to establish that AMPHOMUL ® is safe, at least
                  as effective and more affordable than current treatment, and
                  is without the risk of drug resistance. The trial is a
                  Prospective, Multicentric, Randomized, Two Arm, Open label
                  Phase III study to Assess Efficacy and Safety of Infusion of
                  Amphomul® (Amphotericin B Emulsion) as Compared to Liposomal
                  Amphotericin B in Patients of Visceral Leishmaniasis (Kala
                  azar)who are either treatment naive or treatment resistant to
                  other antileishmanial drugs.
                </p>
              </div>
              <div className="upcoming_qualify mb-3">
                <h3 className="my-2">Who Qualifies?</h3>
                <div className="row d-flex align-items-center justify-content-between my-4 px-4">
                  <div className="text-center col-lg-3 col-sm-12 col-md-12 col-xs-12">
                    <img src={enroll_gender} alt="gender"></img>
                    <p className="mb-0 my-3">Gender: All</p>
                  </div>
                  <div className="text-center col-lg-5 col-sm-12 col-md-12 col-xs-12">
                    <img src={enroll_age} alt="age"></img>
                    {/* <div className="my-3">
                      <span className="mb-0 mx-1">Max - 70</span>
                      <span className="mb-0 mx-1">Min - 20</span>
                    </div> */}
                    <div className="my-3">
                      <span className="mb-0 mx-1">Min - 5 years</span>
                      <span className="mb-0 mx-1">Max - 65 years</span>
                    </div>
                  </div>
                  <div className="text-center col-lg-3 col-sm-12 col-md-12 col-xs-12">
                    <img src={enroll_rank} alt="rank"></img>
                    {/* <p className="mb-0 my-3">Conditions: 1</p> */}
                    <p className="mb-0 my-3">Conditions: None</p>
                  </div>
                </div>
              </div>
              <div className="upcoming_purpose">
                <h3 className="my-2 upcoming_criteria">Criteria</h3>
                <h2 className="my-3">Inclusion Criteria</h2>
                {/* <p className="mb-0">
                  For more information regarding Bristol-Myers Squibb Clinical
                  Trial participation, please visit www.BMSStudy Connect.com
                  Inclusion Criteria: Participants with liver biopsy fibrosis
                  score stage 4 performed within 6 months Men and women must
                  agree to follow methods of contraception
                </p> */}
                <ul>
                  <li>
                    Male or female patients aged between 5 to 65 years (both
                    inclusive).
                  </li>
                  <li>
                    Clinical signs and symptoms of Visceral Leishmaniasis (fever
                    of over 2 weeks duration and splenomegaly)
                  </li>
                  <li>
                    Presence of amastigotes (Leishmania-Donovani bodies) at
                    prescreening detected by rK39 dipstick test with
                    confirmation by splenic or bone marrow aspirate smear
                    examination.
                  </li>
                  <li>
                    Non-pregnant, non-lactating females of age ≥5 years, and
                    woman of childbearing potential who are willing to use
                    acceptable methods of contraception
                  </li>
                  <li>Negative Urine pregnancy test (UPT) in all women</li>
                </ul>
              </div>
              <div className="my-2 upcoming_purpose">
                <h2 className="my-3">Exclusion Criteria</h2>
                {/* <p className="mb-0">
                  Worsening liver disease or any disease might compromise
                  participant safety in the opinion of the investigator Known
                  immunocompromised status or any disease or condition which
                  might compromise participant safety Prior exposure to
                  BMS-986263 Clinically relevant abnormal physical examination,
                  vital signs, ECG, or clinical laboratory tests Hepatic
                  decompensation Other protocol-defined inclusion/exclusion
                  criteria apply
                </p> */}
                <ul>
                  <li>
                    Patients with past history of treatment with Amphotericin B
                    or any other drug for Visceral Leishmaniasis within 30 days
                    prior to screening.
                  </li>
                  Patients positive for HIV, HCV and HBsAg infection,
                  immunocompromised patients (through history).
                  <li>
                    Concurrent diabetes, tuberculosis or bacterial pneumonia or
                    any other infectious or major psychiatric disease.
                  </li>
                  <li>Pregnant or nursing women</li>
                  <li>
                    Patients receiving any of the medications prohibited by the
                    study protocol.
                  </li>
                  <li>{`Simultaneous participation in another trial or received any IP <30 days prior to enrolment.`}</li>
                </ul>
              </div>
              <div id="map" className="container my-3">
                <h3>Locations</h3>
                <Map
                  isMarkerShown={true}
                  toEnroll={true}
                  position={[
                    {
                      actual_address: "Plano TX United States of America",
                      address: "Plano, TX, USA",
                      location: { lat: 33.0198431, lng: -96.6988856 },
                      studyId: "STU01667",
                    },
                    {
                      actual_address: "10701 Corporate Dr, Stafford, TX 77477",
                      address:
                        "10701 Corporate Dr #310, Stafford, TX 77477, USA",
                      location: { lat: 29.6326033, lng: -95.59397779999999 },
                      studyId: "STU01619",
                    },
                  ]}
                  showMap={true}
                  containerElement={
                    <div style={{ height: `400px`, width: "1100px" }} />
                  }
                  googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${Google_Map_Key}`}
                  loadingElement={<div style={{ height: `100%` }} />}
                  mapElement={<div style={{ height: `100%` }} />}
                />
              </div>
            </div>
            <div className="col-lg-3 upcoming_recent_blogs my-3">
              <h2>Recent Blogs</h2>
              {[firstBlog, secondBlog].map((suggestion) => (
                <div className="my-3">
                  <img
                    className="img-responsive"
                    src={suggestion.image}
                    alt={"blog_image_" + suggestion.id}
                  ></img>
                  <p className="mb-1">
                    {suggestion.title && suggestion.title.length > 30
                      ? suggestion.title.slice(0, 30) + " ..."
                      : suggestion.title}
                  </p>
                  <div className="upcoming_recent_date d-flex align-items-center justify-content-start">
                    <span>{suggestion.date}</span>
                    <span className="mx-1">|</span>
                    <span>{suggestion.author}</span>
                  </div>
                </div>
              ))}
              <div>
                {activeTrials && activeTrials.length > 0 ? (
                  <h2>Active Trials</h2>
                ) : null}
                {activeTrials && activeTrials.length > 0
                  ? [
                      activeTrials[getRandomNumber(activeTrials)],
                      activeTrials[getRandomNumber(activeTrials)],
                      activeTrials[getRandomNumber(activeTrials)],
                    ].map((suggestion) => (
                      <div
                        style={{
                          border: "1px solid rgba(0, 0, 0, 0.5)",
                          cursor: "pointer",
                        }}
                        className="my-3 p-3 border"
                        onClick={() =>
                          trialSuggestionClick(
                            suggestion.snow_study_number,
                            suggestion.static_recruitment_link
                          )
                        }
                      >
                        <p className="mb-1">
                          {suggestion.brief_title &&
                          suggestion.brief_title.length > 200
                            ? suggestion.brief_title.slice(0, 200) + " ..."
                            : suggestion.brief_title}
                        </p>
                      </div>
                    ))
                  : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="">
      {/* <Helmet>
        <script type="text/javascript">
          {`(function(w, d, x, id){
    s=d.createElement('script');
    s.src='https://db08fjupg2abb.cloudfront.net/amazon-connect-chat-interface-client.js';
    s.async=1;
    s.id=id;
    d.getElementsByTagName('head')[0].appendChild(s);
    w[x] =  w[x] || function() { (w[x].ac = w[x].ac || []).push(arguments) };
  })(window, document, 'amazon_connect', 'c8bbad0f-2307-4b80-b309-35abda12794c');
  amazon_connect('styles', { openChat: { color: 'white', backgroundColor: '#123456'}, closeChat: { color: 'white', backgroundColor: '#123456'} });
  amazon_connect('snippetId', 'QVFJREFIaEtSZldVcU5UMEdSandGVG01V3F6YWxjVk4vRzUreHdXTlJ3MnRlRUcvTHdGSFhyekQrSVJmZXZ6RUxrWlRHOEtpQUFBQWJqQnNCZ2txaGtpRzl3MEJCd2FnWHpCZEFnRUFNRmdHQ1NxR1NJYjNEUUVIQVRBZUJnbGdoa2dCWlFNRUFTNHdFUVFNcFZ1TmJadXBxclNZS1BNcUFnRVFnQ3ZqNzZaWHE1c1pXS1h0QTFTTThIL1dleE52S2VybzFRQ04rcElnSzU4YzBNMThhc1hSYnVEVmVwTmI6Ok9lZjZMTHJ3Q2k2MHpzL0pHTGVEZGswRkgyNGI0MEViZ0xYbG40MVM3THF6WUIrMi9ML2tjUGZmL2J1OWlOaTBUMjVkWW9UVWNzRG1vR1l3MUhjN0JPTUlqdnlpMUFJKzV1WUdVR2xxU0c0QzRRR0RUSThrTkQ0TnhLeUhOOEVZdXpCREhvTEMzaDQzVE5vMVR6dmM0NlNBV2ZkNkNhYz0=');`}
        </script>
      </Helmet> */}
      <RecruitmentSteps step={"2. Review Trials"} />
      <TrialDetails />
    </div>
  );
};
export default PtEnrollScreen;
