import React from "react";
import { DataNotFound, RecruitmentSteps, ScreenFlow } from "../../components";
import {
    template5site,
    template5nct,
    template5gender,
    template5status,
    template5identifier,
    template5minimum,
    template5maximum,
  template3qualify,
  greenDot,
  saveButton,
  template2gender1,
  template3age,
  template5save,
} from "../theme/icons";
import {
  enrollLink,
  getInclusionCriteria,
  getExclusionCriteria,
} from "../../helpers/helper";

const Template5 = (props) => {
  const { trial, searchTerm } = props;

  const flowarray = (studyNumber) => {
    return [
      { title: "Home", link: "/" },
      { title: "Find Trials", link: "/#find_trial" },
      {
        title: "Search Results",
        link: `/results?q=${studyNumber}&in=UNITED%20STATES`,
      },
      {
        title: "Trial Details",
        link: `/preview/${studyNumber}?template=${searchTerm}`,
      },
    ];
  };

  const studyIdentifiers = () => {
    return [
      {
        id: 1,
        logo: template5identifier,
        title: (trial && trial.nct_number) || "None",
        subtitle: "Clinical Study Identifier",
        bg:'#76D6EE',
      },
      {
        id: 2,
        logo: template5site,
        title: (trial && trial.site_name) || "None",
        subtitle: "Site Name",
        bg:'#C9AFD2',
      },
      {
        id: 3,
        logo: template5status,
        title: (trial && trial.status) || "None",
        subtitle: "Trial Status",
        bg:'#8CD2A9',
      },
    ];
  };

  const studyQualifications = () => {
    return [
      {
        id: 1,
        logo: template5gender,
        title: "Gender",
        subtitle: (trial && trial.sex) || "None",
        bg: "#C9AFD2",
      },
      {
        id: 2,
        logo: template5nct,
        title: "Study ID",
        subtitle: (trial && trial.snow_study_number) || "None",
        bg: "#F2D888",
      },
      {
        id: 3,
        logo: template5maximum,
        title: "Maximum Age",
        subtitle: (trial && trial.maximum_age) || "None",
        bg: "#F2D888",
      },
      {
        id: 4,
        logo: template5minimum,
        title: "Minimum Age",
        subtitle: (trial && trial.minimum_age) || "None",
        bg: "#E99781",
      },
    ];
  };

  return (
    <div>
      {trial === null ? (
        <DataNotFound message="Selected template not found" />
      ) : (
        <div>
          <div>
            <div className="container">
              <RecruitmentSteps step={"Review Trials"} />
              <div className="d-flex align-items-center justify-content-start">
                <ScreenFlow
                  flow={flowarray(trial && trial.snow_study_number)}
                />
              </div>
              <div className="d-flex align-items-center justify-content-center flex-column py-5 my-0">
                <p className="t5_title mb-0 col-5 ml-4">
                  {(trial && trial.brief_title) || "None"}
                </p>
                {/* <h6 className="mb-0">Location Details</h6>
                    <p className="my-2 mb-0 site">
                      {(trial && trial.site_name) || "None"}
                    </p>
                    <p className="mb-0">
                      {(trial && trial.street_address) || "None"},{" "}
                      {(trial && trial.city) || "None"}
                    </p>
                    <p className="mb-0">
                      {(trial && trial.state) || "None"},{" "}
                      {(trial && trial.country) || "None"},{" "}
                      {(trial && trial.pin_code) || "None"}
                    </p> */}
                <button
                  onClick={() =>{
                    if(trial && trial.active === "true" && trial.promote === "true"){
                      enrollLink(trial && trial.snow_study_number)
                    }
                  }
                  }
                  disabled = {(trial && trial.active === "true" && trial.promote === "true") ? false : true}
                  className="upcoming_signup btn btn-lg my-3"
                >
                  Sign Up
                </button>
              </div>
            </div>
            <div style={{ backgroundColor: "#F6F8FD" }} className="container-fluid py-5">
                <div className="container">
                  <div className="col-lg-10 text-left px-0">
                    <div className="row text-left template2_title">
                      <div className="d-flex align-items-center justify-content-center col-xs-12">
                        <img
                          className="mt-1"
                          style={{ height: 17, width: 17 }}
                          src={greenDot}
                          alt="dot"
                        ></img>
                        <p className="mb-0 pl-2 pr-5 template2_study_number">
                          {(trial && trial.snow_study_number) || "None"}
                        </p>
                      </div>
                      <div className="d-flex align-items-center justify-content-center col-xs-12">
                        <p className="mb-0 mx-lg-5 mx-md-5 mx-sm-5">
                          {(trial && trial.study_name) || "None"}
                        </p>
                        <img src={template5save} alt="savetag"></img>
                      </div>
                    </div>
                  </div>
                  <div className="row mx-0 px-0 my-2">
                    {studyIdentifiers().map(identifier=>
                        <div className="col-lg-4 my-2 d-flex align-items-center justify-content-center flex-column">
                            <img className="my-4" src={identifier.logo} alt="template5 logo"></img>
                            <div className="text-left">
                                <p className="template4_subtitle mb-0">{identifier.subtitle}</p>
                                <h6 className="template4_title mb-0">{identifier.title}</h6>
                            </div>
                        </div>
                    )}
                  </div>
                </div>
            </div>
            <div className="container">
                <div>
                  <div className="upcoming_purpose my-3">
                    <h2 className="my-3">Purpose</h2>
                    <p style={{ fontWeight: "normal" }} className="mb-0">
                      {(trial && trial.brief_summary) || "None"}
                    </p>
                  </div>
                  <br />
                  <div className="upcoming_purpose my-3">
                    <h2 className="my-3">Description</h2>
                    <p style={{ fontWeight: "normal" }} className="mb-0">
                      {(trial && trial.detailed_description) || "None"}
                    </p>
                  </div>
                </div>
            </div>
            <div style={{ backgroundColor: "#ECECEC" }} className="container-fluid">
                <div className="container py-3 py-5">
                    <div className="upcoming_qualify">
                      <h3 className="my-2">Who Qualifies?</h3>
                    </div>
                    <div className="t5_container flex-xs-column mx-0 px-0 col-lg-12 py-3">
                      {studyQualifications().map(qualification=>
                        <div style={{backgroundColor: "#ECF0F6", borderRadius: 10}} className="col-lg-3 mr-4 my-2 pl-4 pt-3 d-flex align-items-start justify-content-center flex-column t5_identifier">
                            <div className="template4icon py-2 my-3">
                              <img style={{ height:50, width: 50 }} src={qualification.logo} className="mb-5" alt="template4 icon"></img>
                              <div className="text-left">
                                <h6 className="template4_title mb-0">{qualification.title}</h6>
                                <p className="template4_subtitle mb-0">{qualification.subtitle}</p>
                              </div>
                            </div>
                        </div>
                      )}
                    </div>
                </div>
            </div>
            <div className="container">
              <div className="col-lg-12 my-5">
                <div className="upcoming_purpose">
                  <h3 className="my-2 upcoming_criteria">Criteria</h3>
                  <h2 className="my-3">Inclusion Criteria</h2>
                  <p style={{ fontWeight: "normal" }} className="mb-0">
                    {getInclusionCriteria(trial && trial.eligibility)}
                  </p>
                </div>
                <div className="my-2 upcoming_purpose">
                  <h2 className="my-3">Exclusion Criteria</h2>
                  <p style={{ fontWeight: "normal" }} className="mb-0">
                    {getExclusionCriteria(trial && trial.eligibility)}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Template5;
