import React, { useState } from "react";
import {
  selectedMapIcon,
} from "../features/theme/icons";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from "react-google-maps";
import { log } from "../helpers/helper";

const Map = withScriptjs(
  withGoogleMap((props) => {
    const { position, showMap, toEnroll } = props;
    const [details, showdetails] = useState(false);
    const [site, setSite] = useState("");
    const [label, setLabel] = useState("");
    const [studyId,setStudyId] = useState("")
    // log(position, "pos");

    return (
      <div>
        {showMap === true ? (
          <GoogleMap
            defaultZoom={4}
            defaultCenter={(position && position[5] && position[5].location && position[5].location) || (position[0].location)}
            mapTypeId={"roadmap"}
          >
            {position && position.length > 0
              ? position.map((pos, i) => (
                  <Marker
                    key={i}
                    onClick={() => {
                      setLabel(pos.address);
                      setSite(pos.site_name)
                      setStudyId(pos.studyId)
                      showdetails(true);
                    }}
                    // onMouseOut={()=>{
                    //   setLabel("");
                    //   setStudyId("")
                    //   showdetails(false);
                    // }}
                    position={pos.location}
                    icon={selectedMapIcon}
                  />
                ))
              : null}
          </GoogleMap>
        ) : null}
        {details === true ? 
        <div style={{backgroundColor:'#F3F3F3',position:'relative',bottom:250,left:700}} className="col-lg-4 py-4">
            <div className="d-flex align-items-center justify-content-between">
                  { toEnroll === true ? <p className="mb-0">Site Name</p> : null}
                  <p onClick={()=>showdetails(false)} style={{height: 25, borderRadius: 10, border: '1px solid #BC5405', cursor:'pointer'}} className="mb-0 px-2"><strong>close</strong></p>
            </div>
            <h4>{site}</h4>
            <p className="mb-0">Selected Location</p>
            <h4>{label}</h4>
            { toEnroll === true ? <button style={{backgroundColor:'#BC5405'}} onClick={()=>window.location.href=`/enroll/${studyId}`} className="btn btn-sm btn-danger border-none">Sign Up</button> : null }
        </div> : null}
      </div>
    );
  })
);

export default Map;
