import React, { useState, useEffect } from 'react';
import { log } from '../helpers/helper';

const PreScreen = ({ questions, optionChange, onEnroll }) => {
  const [order_questions, set_order_questions] = useState([])


  useEffect(()=>{
    if(questions && questions.length > 0){
      //let q_order = []
      // log(questions, 'qs in prescreening')
      let check_for_order_empty = questions.filter(q => (q && q.order === "") || (q && q.order === " ") || (q && q.order === null) || (q && q.order === undefined))
      log(check_for_order_empty,'order related')
      if(check_for_order_empty && check_for_order_empty.length === 0){
        let questions_order = questions.map(q=> parseInt(q.order)).sort()
        // log(questions_order)
        if(questions_order && questions_order.length > 0){
          // questions_order.map(item => {
          //   let filter_question = questions.filter(ques => parseInt(ques.order) === item)
          //   if(filter_question && filter_question.length > 0){
          //     q_order.push(filter_question[0])
          //   }else{
          //     // log('err while ordering questions', filter_question)
          //   }
          // })
          let q_order = questions.sort((a, b) => parseInt(a.order) - parseInt(b.order));
          log(q_order,'ordered questions')
          set_order_questions(q_order)
        } else{
          // log('error fetching order')
          set_order_questions(questions)
        }
      }else{
        questions.sort((q1, q2) =>
                          new Date(q1.sys_created_on).getTime() -
                          new Date(q2.sys_created_on).getTime()
                      )
        set_order_questions(questions)
      }
      // questions.map(ques =>{
      //   if((ques && ques.order === "") || (ques && ques.order === " ") || (ques && ques.order === null) || (ques && ques.order === undefined)){
      //     ques.order = 0
      //   }
      // })
    }else{
      set_order_questions([])
    }
  },[])

  const check_ordinals = (question) =>{
    if(question && question.length > 0){
      let detect_ordinals = question.match(/\b\d+(st|nd|rd|th)\b/g);
      log(detect_ordinals,'ordinals');
      if((detect_ordinals && detect_ordinals.length > 0) && (question.startsWith("Are you in your 2"))){
        return <span style={{fontWeight:'normal'}}>Are you in your 2<span style={{fontWeight:'normal'}}><sup>nd</sup></span> or 3<sup>rd</sup> trimester of pregnancy?</span>
      }else{
        return question
      }
    }
  }

    return (
      <div style={{backgroundColor : "#c9d6d9"}} className="prescreen px-1 mb-5">
        <div className='d-flex align-items-center justify-content-center'>
          <div>
        <h2 className='text-center'>Pre-screening Questions</h2>
        {order_questions && order_questions.length === 0 ? (
          <p>No Pre-screening Questions Found</p>
        ) : (
          order_questions.map((question, i) => (
            <div className="question my-3" key={question.sys_id}>

              <h3>{i+1} . {check_ordinals(question.question)}</h3>
              <div className="d-flex align-items-center justify-content-start">
                <div className="prescreen_option mr-2 form-check">
                  <input
                    type="radio"
                    onChange={optionChange}
                    value={"true"}
                    name={question.sys_id}
                    className="form-check-input"
                    style={{cursor:'pointer'}}
                  />{" "}
                  <span>Yes</span>
                </div>
                <div className="prescreen_option mx-2 form-check">
                  <input
                    type="radio"
                    onChange={optionChange}
                    value={"false"}
                    name={question.sys_id}
                    className="form-check-input"
                    style={{cursor:'pointer'}}
                  />{" "}
                  <span>No</span>
                </div>
              </div>
            </div>
          ))
        )}
          </div>
        </div>
          <div className='d-flex align-items-center justify-content-center'>
              <button onClick={onEnroll} className="btn btn-lg btn-primary gn_btn">
                NEXT
              </button>
          </div>
      </div>
    );
  };


  export default PreScreen