import React from "react";
import "../App.css";
import "../bootstrap.css";
import {
  infoLight,
  infoDark,
  chevronRightDark,
  chevronRightLight,
} from "../features/theme/icons";

const steps = ["1. Find A Trial", "2. Review Trials", "3. Self Assessment", "4. Enroll"];

const RecruitmentSteps = (props) => {
  // provide index value so that prop will match with the steps arrayitem
  return (
    <div>
      <div style={{backgroundColor: '#F9F9F9'}} className="recruitment_steps py-0 border-bottom d-flex align-items-center justify-content-center">
        <div className="container px-1 mx-0">
          <div className="row d-flex align-items-center justify-content-between mr-5">
            {steps.map((step, i) => (
              <div
                key={i}
                style={{borderBottom : step === steps.filter((item) => item === props.step)[0] ? '3px solid #034EA2' : "none"}}
                className="text-left"
              >
                <h2
                  style={{
                    // fontFamily: "LatoRegular",
                    // fontStyle: "normal",
                    // fontWeight: "bold",
                    // fontSize: "14px",
                    // textTransform: "uppercase",
                    fontFamily : step === steps.filter((item) => item === props.step)[0]
                    ? "NunitoBold"
                    : "NunitoRegular",
                    fontWeight: step === steps.filter((item) => item === props.step)[0]
                    ? "bold"
                    : "normal",
                    color:
                      step === steps.filter((item) => item === props.step)[0]
                        ? "#034EA2"
                        : "#BDBDBD",
                  }}
                  className="py-2 recruitment_step mb-0"
                >
                  {step === steps.filter((item) => item === props.step)[0]
                    ? steps.filter((item) => item === props.step)[0]
                    : step}
                </h2>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecruitmentSteps;
