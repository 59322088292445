import React from "react";
import { Domain, environment } from "../../constants";
import { getTrialId } from "../../helpers";
import axios from "axios";
import ThankYou from "./ThankYou";
import Participant from "./Participant";
import { checkForLettersOnly, log, validateEmail } from "../../helpers/helper";
import { getQuestionGroup } from "../../helpers";
import { v4 as uuidv4 } from "uuid";

const disabledPromoTrials = ["b58e6fccdba8e4505ca4bc04b99619df"];

class EnrollmentController extends React.Component {
  constructor(props) {
    super();
    this.country = window.sessionStorage.getItem("country");
    this.condition = window.sessionStorage.getItem("condition");
    this.race = {};
    this.state = {
      recaptcha_response : "",
      type: "",
      isLoading: false,
      showEmailError: false,
      showFirstNameError: false,
      showLastNameError: false,
      showPhoneError: false,
      showEcPhoneError: false,
      empty_fields_err: false,
      accept_terms: true,
      empty_fields : [],
      phase: "",
      race: "",
      ethnicity : "",
      scrolled_field: "",
      conditions: [],
      condition: "",
      studyId: "",
      firstname: "",
      nctNumber: "",
      lastname: "",
      email: "",
      height: "",
      inches:"",
      weight : "",
      street_address : "",
      ec_fullname: "",
      ec_phone: "",
      ec_relationship: "",
      country: "UNITED STATES",
      showRegisterOptions: false,
      city: "",
      state: "",
      participant_id: "",
      zipcode: "",
      gender: "",
      current_gender: "",
      dob: "",
      phone: "",
      smokingstatus: "",
      primarylanguage: "",
      currentmedication: "",
      healthcondition: "",
      trial: null,
      familyhistory: "",
      stateterritory: "",
      timezone: "",
      patient_id: "",
      pt_uname: "",
      domain: "",
      isParticipantEnrolled: false,
      patientSysId: "",
      showModal: false,
      showAcknowledgement: false,
      loadingForModal: false,
      criteria: "",
      currentpage: "",
      questions: [],
      showPrescreening: false,
      show_patient_id: "false",
    };
  }

  onRecaptchaChange = (value) =>{
    log(value,'rv')
    this.setState({ recaptcha_response : value})
  }

  checkForHyphensError = (number) =>{
    if(number && number.length < 12){
      return true
    }
    if(number && number.length > 8){
      let place_3 = number.at(3)
      let place_7 = number.at(7)
      let other_digits =  number.split('').map((digit,i)=>{
        if(i !== 3 || i !== 7){
          return digit
        }
      })
      //[number.at(0),number.at(1),number.at(2),number.at(4),number.at(5), number.at(6), number.at(8), number.at(9), number.at(10), number.at(11)]
      //log(other_digits,place_3,place_7,'values');
      if(other_digits && other_digits.length > 0){
        let other_places = other_digits.filter(item => item === '-')
        log(other_places,other_digits,place_3,place_7,'values');
        if((place_3 == "-" && place_7 == "-") && (other_places && other_places.length === 2) && (number && number.length) === 12 ){
          return false
        } else{
          return true
        }
      }
    }
  }

  insertChars = (number) =>{
    if(number && number.length > 0){
        let cleaned = ('' + number).replace(/\D/g, '');
        let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            return `${match[1]}-${match[2]}-${match[3]}`;
        }
        let replace_others = number.replace(/[^0-9\-]/g, '')
        let modified = replace_others.slice(0,12);
        return modified;
    }
  }


  onTextInputChange = (e) => {
    //log(`${e.target.name}, ${e.target.value}, ${e.target}`);
    this.setState({ [e.target.name]: e.target.value }, () => {
      if(this.state.firstname && this.state.firstname.length > 0){
        let fname = this.state.firstname;
        this.setState({firstname: fname.replace(/[^a-zA-Z]/g, '')},()=>{
          this.setState({scrolled_field:""})
        })
      } 
      if(this.state.lastname && this.state.lastname.length > 0){
        let lname = this.state.lastname;
        this.setState({lastname: lname.replace(/[^a-zA-Z]/g, '')},()=>{
          this.setState({scrolled_field:""})
        })
      } 
      if(this.state.current_gender && this.state.current_gender.length > 0){
        let cGender = this.state.current_gender;
        this.setState({current_gender: cGender.replace(/[^a-zA-Z]/g, '')})
      } 
      if(this.state.ec_fullname && this.state.ec_fullname.length > 0){
        let ecFullName = this.state.ec_fullname;
        this.setState({ec_fullname: ecFullName.replace(/[^a-zA-Z\s]/g, '')})
      } 
      if(this.state.ec_relationship && this.state.ec_relationship.length > 0){
        let ecRelationship = this.state.ec_relationship;
        this.setState({ec_relationship: ecRelationship.replace(/[^a-zA-Z]/g, '')})
      } 
      if(this.state.phone && this.state.phone.length > 0){
        let user_phone = this.state.phone;
        let modified_ph =  this.insertChars(user_phone) 
        //&& this.insertChars(user_phone).replace(/[^0-9\-]/g, '').slice(0,12)
        this.setState({phone: modified_ph},()=>{
          log(this.checkForHyphensError(modified_ph),'check for ph');
          this.setState({showPhoneError : this.checkForHyphensError(modified_ph)})
        })
      } 
      if(this.state.ec_phone && this.state.ec_phone.length > 0){
        let ec_phone = this.state.ec_phone;
        let modified_ph =  this.insertChars(ec_phone) 
        //&& this.insertChars(user_phone).replace(/[^0-9\-]/g, '').slice(0,12)
        this.setState({ec_phone: modified_ph},()=>{
          log(this.checkForHyphensError(modified_ph),'check for ph');
          this.setState({showEcPhoneError : this.checkForHyphensError(modified_ph)})
        })
      } 
      if(this.state.zipcode && this.state.zipcode.length > 0){
        let zip = this.state.zipcode;
        this.setState({zipcode: zip.replace(/[^0-9]/g, '').slice(0,5)})
      }  
      // if(this.state.state && this.state.state.length > 0){
      //   let user_state = this.state.state;
      //   this.setState({state: user_state.replace(/[^a-zA-Z]/g, '')})
      // }  
      if(this.state.city && this.state.city.length > 0){
        let user_city = this.state.city;
        this.setState({city: user_city.replace(/[^a-zA-Z\s]/g, '')})
      }
      else if (
        this.state.email !== null ||
        this.state.email !== "" ||
        this.state.email !== undefined
      ) {
        let checkEmail = validateEmail(this.state.email);
        if (checkEmail === false) {
          this.setState({ showEmailError: true });
        } else if (checkEmail === true) {
          this.setState({ showEmailError: false });
        }
      }
    });
  };

  onPhoneNumChange = (e) =>{
   this.setState({ phone: e.target.value},()=>{
    let phone = this.state.phone.replace(/\D/g, '');
    if(phone && phone.length > 0){
      if(phone && phone.length > 10){
        this.setState({phone: phone.slice(0,10)})
      }
     }
   })
  }

  optionChange = (e) => {
    //log(`${e.target.name}, ${e.target.value}`);
    this.setState({ [e.target.name]: e.target.value });
  };
  howManyQuestionsAnswered = (questions) => {
    let checkAnswer = [];
    if (questions && questions.length > 0) {
      questions.map((questionID) =>
        checkAnswer.push(this.state.hasOwnProperty(questionID))
      );
      return checkAnswer;
    }
  };

  submitAnswers = () => {
    let questions = this.state.questions;
    if (questions && questions.length > 0) {
      this.state.questions.map((ques) => {
        //log(this.state[ques.sys_id], "for submitting");
        return axios
          .post(
            "/backend/submit",
            {
              answer: this.state[ques.sys_id],
              sys_id: ques.sys_id,
              order: ques.order,
              participant_id: this.state.participant_id,
            },
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          )
          .then((suc) => {
            //log(`${suc}, "submitted answers"`);
            this.setState({ showPrescreening: false });
          })
          .catch((e) => {
            this.setState({ showPrescreening: false });
          });
      });
      setTimeout(() => {
        this.onAcknowledge(this.state.accept_terms)
      }, 1000);
    } else {
      log(questions, "no questions found");
      return;
    }
  };

  getCorrectAnswers = (questions) =>{
    if(questions && questions.length > 0){
      let answers = {};
      questions.map(question => {
        return answers[question.sys_id] = question.answer
      })
      return answers
    }else{
      return null
    }
  }

  validatePrimaryScreener = (questions) => {
    log(questions, 'got qs')
    if (questions  && questions.length > 0){
      let correct_responses = this.getCorrectAnswers(questions)
      if(correct_responses !== null){
        let qFields = Object.keys(correct_responses);
      log(qFields,'qf')
      if(qFields && qFields.length > 0){
       let right_answers = qFields.filter(item => correct_responses[item] === this.state[item])
       log(right_answers,'r ans')
       if((right_answers && right_answers.length)  === (qFields && qFields.length)){
        log(right_answers, qFields,'validation is successful, going forward')
        this.setState({ showPrescreening: false });
       }else{
        log(right_answers, qFields, 'err validation')
        alert(`Thank you for your interest in our clinical trial study ${this.state.trial && this.state.trial.nct_number}. We regret to inform you that you do not meet the specific criteria required for this trial.`)
        window.location.href = `/enroll/${this.state.studyId}?embed=true`
       }
      }
      }


      // questions.map(question => {
      //   return user_responses[question.sys_id] = this.state[question.sys_id]
      // })
      // log(user_responses,'user responses')
      // let correct_answers = questions.map(question => question.answer)
      // log(correct_answers,'correct answers')
      // let arr = []
      // for(let i=0;i<questions.length; i++){
      //   user_responses.map(user_option =>{ 
      //     log(user_option,correct_answers[i],'while comparing')
      //     if(user_option === correct_answers[i]){
      //       return arr.push("true")
      //     }else{
      //       return arr.push("false")
      //     }
      //   })
      // }
      // if(arr && arr.length > 0){
      //   log(arr,'before filtering')
      //   let validation = arr.filter(response => response === "false")
      //   if(validation && validation.length > 0){
      //     log(validation, 'validation')
      //     alert("Primary screener validation failed, you are not eligible to participate in this trial")
      //     window.location.href = "/"
      //   }else{
      //     log(validation,'validation is successful, going forward')
      //     this.setState({ showPrescreening: false });
      //   }
      // }
    }else{
      log(questions, 'questions while validating ps')
      alert("Error while validating primary screener. Please try after sometime!")
    }
  }

  onEnroll = () => {
    if (this.state.questions && this.state.questions.length > 0) {
      let question_ids = this.state.questions.map((id) => id.sys_id);
      if (
        this.howManyQuestionsAnswered(question_ids) &&
        this.howManyQuestionsAnswered(question_ids).filter(
          (answer) => answer === false
        ) &&
        this.howManyQuestionsAnswered(question_ids).filter(
          (answer) => answer === false
        ).length > 0
      ) {
        alert("Please enter all pre-screening questions");
      } else {
        //this.setState({ showPrescreening: false });
        this.validatePrimaryScreener(this.state.questions)
      }
    } else {
      this.setState({ showPrescreening: false });
    }
    this.setState({ showRegisterOptions: true });
  };

  getRandomNumber = () => {
    let random_id = uuidv4();
    log(random_id, "random number generated");
    return random_id;
  };

  alignPrescreeningResponses = () => {
    let responses = [];
    let questions = this.state.questions;
    if (questions && questions.length > 0) {
      questions.map((question) => {
        let response = {
          question: question.question,
          sys_id: question.sys_id,
          answer: this.state[question.sys_id],
        };
        return responses.push(response);
      });
      log(responses, "all responses");
      if (responses && responses.length > 0) {
        window.localStorage.setItem("prescreening", JSON.stringify(responses));
      }
    } else {
      log(questions, "no questions found");
    }
  };

  showTerms = ()=>{
    this.setState({ showAcknowledgement: true, loadingForModal: true });
  }

  scrollToField = (field) => {
    if(field && field.length > 0){
      const fieldDiv = document.getElementById(field);
      if (fieldDiv) {
        fieldDiv.scrollIntoView({ behavior: 'smooth',  block: 'start' });
        this.setState({scrolled_field: field})
        // let yOffset = -50;
        // let y = fieldDiv.getBoundingClientRect().top + window.scrollY + yOffset;
        //window.scrollTo({ top: y, behavior: 'smooth' });
      }
    }
  };

  navigateOption = (title) => {
    if (title === "Enter Manually") {
      this.setState({ showRegisterOptions: false });
    } else if (title === "Connect My EMR") {
      let randomNumber = this.getRandomNumber();
      if (randomNumber && randomNumber.length > 0) {
        window.localStorage.setItem("nct_id", this.state.nctNumber);
        window.localStorage.setItem("prescreening", this.state.questions);
        window.localStorage.setItem("study_number", this.state.studyId);
        this.alignPrescreeningResponses();
        setTimeout(() => {
          window.location.href = `/connect-to-emr/${randomNumber}/${this.state.studySysId}/${this.state.domain}/${this.state.studyId}`;
        }, 1500);
      } else {
        log(randomNumber, "err generating random number");
        alert("Error occured. Please try again");
      }
    } else if (title === "Upload Document") {
      this.alignPrescreeningResponses();
      setTimeout(() => {
        window.location.href = `/upload-document/${this.props.match.params.studyId}`;
      }, 1500);
    }
  };

  onRaceCheckboxChange = (e, qid) => {
    if (e.target.checked) {
      //log(`${e.target.checked}, ${e.target.value}, "checking race inputs"`);
      if (e.target.type === "checkbox") {
        this.race.hasOwnProperty("race")
          ? this.race["race"].push(e.target.value)
          : (this.race["race"] = [e.target.value]);
      } else {
        this.race.hasOwnProperty("race")
          ? this.race["race"].push(e.target.value)
          : (this.race["race"] = [e.target.value]);
      }
    } else {
      if (e.target.type === "checkbox") {
        //log(`${e.target.checked}, ${e.target.value}, "checking race inputs"`);
        if (this.race.hasOwnProperty("race")) {
          let arr = this.race["race"].indexOf(e.target.value);
          this.race["race"].splice(arr, 1);
        }
      }
    }
    //log(`${this.race}, "in race oncheckbox"`);
    this.setState({ race: this.race["race"].toString() });
  };

  onTermsCheckboxChange = (e) =>{
    log(e.target.value, e.target.checked, typeof(e.target.checked), typeof(e.target.value),'tc')
    if(e.target.checked){
      this.setState({accept_terms: true})
    }else{
      this.setState({accept_terms: false})
    }
  }

  signUp = () => {
    let phone_err = this.state.showPhoneError;
    let email_err = this.state.showEmailError;
    let ecPhone_err = this.state.showEcPhoneError
    const {
      city,
      state,
      firstname,
      lastname,
      email,
      gender,
      current_gender,
      phone,
      dob,
      inches,
      race,
      smokingstatus,
      primarylanguage,
      empty_fields_err,
      empty_fields,
      studySysId,
      country,
      zipcode,
      height,
      weight,
      ec_fullname,
      ec_phone,
      ethnicity,
      ec_relationship,
      street_address,
      stateterritory,
      timezone,
      patient_id,
      currentmedication,
      healthcondition,
      familyhistory,
      nctNumber,
      domain,
      criteria,
      currentpage,
      showModal,
      participant_id,
      studyId,
      recaptcha_response,
      showEcPhoneError,
      showEmailError,
      showPhoneError
    } = this.state;
    let checkEmptyValues = {
      "First Name": firstname,
      "Last Name" : lastname,
      "Email": email,
      "Gender at Birth": gender,
      "Date of Birth": dob,
      "Primary Contact Number": phone,
      "Height": height,
      "Inches": inches,
      "Weight": weight,
      "Ethnicity" : ethnicity,
      "Street Address": street_address,
      "City": city,
      "State": state,
      "Zipcode": zipcode,
      "Country": country,
      "Emergency Contact Fullname": ec_fullname,
      "Emergency Contact Phone": ec_phone,
      "Emergency Contact Relationship": ec_relationship,
      // smokingstatus,
      // primarylanguage,
      // race,
      //"Health Condition" : healthcondition,
      "Recaptcha" : recaptcha_response,
    };
    let e_values = []
    let check_keys = Object.keys(checkEmptyValues)
    let check = check_keys.map(item=>{
      let filter_empty = checkEmptyValues[item];
      if(filter_empty === "" || null || undefined){
        return e_values.push(item)
      }
    })
    if( validateEmail(this.state.email) == false){
      alert("Please enter valid email address")
    }  
    if(showPhoneError === true){
      alert("Please enter valid primary contact number")
    } 
    if(showEcPhoneError === true){
      alert("Please enter valid emergency contact number")
    }
    else{
    // Object.values(checkEmptyValues).filter(
    //   (item) => item === "" || null || undefined
    // );
    if (e_values && e_values.length > 0) {
      log(`${check},${e_values}, "check"`);
      this.setState({empty_fields : e_values},()=>{
        this.setState({ empty_fields_err : true},()=>{
          this.scrollToField(e_values[0])
        })
      })
      //alert(`please enter all the below required values \n ${e_values.join(',')}`);
    } else {
      //log("do further");
      // if(showPhoneError === true){
      //   alert("Please enter valid primary contact number")
      // }
      // if(showEcPhoneError === true){
      //   alert("Please enter valid emergency contact number")
      // }
      this.setState({ showAcknowledgement: true });
      let request_body = {
        recaptcha_response : recaptcha_response,
        preferred_name: firstname,
        last_name: lastname,
        gender: gender,
        date_of_birth: dob,
        email: email,
        height: `${height}-${inches}`,
        weight: weight,
        s_state: state,
        street_address : street_address,
        emergency_contact_detail : ec_fullname,
        emergency_contact_no : ec_phone,
        emergency_relationship : ec_relationship,
        current_gender : current_gender,
        phone_number: phone,
        race: ethnicity,
        study: studySysId,
        language: "",
        country: country,
        city: city,
        zip_code_or_city: zipcode,
        state_territory: state,
        time_zone: timezone,
        domain: domain,
        patient_id: patient_id,
        nct_number: nctNumber,
        current_medications: "",
        health_condition: healthcondition,
        relevant_family_history: "",
        smoking_status: "",
        guest_id: participant_id,
        other_information:
          criteria === null ? currentpage : criteria + currentpage,
          //`height:${height}-${inches}&&&weight:${weight}&&&ec_fullname:${ec_fullname}&&&ec_phone:${ec_phone}&&&ec_relationship${ec_relationship}&&&street_address:${street_address}`,
        prescreening_qa: "",
        sys_domain: domain,
        study_number: studyId,
      };
      // (environment === "DEV" || environment === "VAYUSHAKTI_DEV") ? request_body["sys_domain"] = domain : log("PROD")
      //log(`${request_body}, "request body"`);
      //log(request_body, 'body');
      axios
        .post("/backend/participant", request_body)
        .then((success) => {
          //log(`${success.status},'then'`);
          if (
            (success && success.status === 201) ||
            (success && success.status === 200)
          ) {
            //log(`${success && success.data.result}, "created participant"`);
            this.setState(
              {
                patient_id:
                  success &&
                  success.data &&
                  success.data.pt_id,
              },
              () => {
                this.setState({ showModal: !showModal });
                let patientSysId =
                  success &&
                  success.data &&
                  success.data.pt_sys_id;
                let uName =
                  success &&
                  success.data &&
                  success.data.u_id;
                this.setState(
                  { patientSysId: patientSysId, pt_uname: uName },
                  () => {
                    this.setState({ participant_id: patientSysId }, () => {
                      window.localStorage.setItem("pt_sys_id", patientSysId)
                      this.submitAnswers();
                    });
                    //this.setState({ showModal: false, loadingForModal: true });
                    //this.setState({ isParticipantEnrolled: true });
                  }
                );
              }
            );
          } else if (
            success &&
            success.data &&
            success.data.failure === "failure"
          ) {
            let participantError =
              success &&
              success.data &&
              success.data.error &&
              success.data.error.message;
            alert(participantError + "Error while creating participant");
            this.setState({
              loadingForModal: false,
              showAcknowledgement: false,
            });
          } else {
            //log(`${success.status},'then'`);
            alert("Error while creating participant");
            this.setState({
              loadingForModal: false,
              showAcknowledgement: false,
            });
          }
        })
        .catch((errSuccess) => {
          // log(
          //   `${errSuccess},${errSuccess.status}, "error creating participant"`
          // );
          //log(errSuccess,errSuccess.response ,'err while creating participant');
          if (
            errSuccess &&
            errSuccess.response &&
            errSuccess.response.status === 400
          ) {
            alert("Error while creating participant");
            this.setState({
              loadingForModal: false,
              showAcknowledgement: false,
            });
          } else if (
            errSuccess &&
            errSuccess.response &&
            errSuccess.response.status === 403
          ) {
            alert(
              "Error while creating participant looks like email already exists"
            );
            this.setState({
              loadingForModal: false,
              showAcknowledgement: false,
            });
          }
        });
      }
    }
  };

  onAcknowledge = (status) => {
    axios
      .post("/backend/acknowledge", {
        sysId: this.state.patientSysId,
        acknowledged: status,
      })
      .then((successAck) => {
        //log(`${successAck && successAck.data}, "on acknowledge"`);
        this.setState(
          {
            isParticipantEnrolled: true,
            showModal: false,
            showAcknowledgement: false,
          },
          () => {
            if (status === "false") {
              window.location.href = "/";
            } else {
              if (
                this.state.trial &&
                this.state.trial.account_sid &&
                this.state.trial.account_sid !== undefined &&
                this.state.trial &&
                this.state.trial.account_sid &&
                this.state.trial.account_sid.length > 0
              ) {
                window.localStorage.setItem("chat_status", "started");
                window.localStorage.setItem(
                  "prospectID",
                  this.state.patient_id
                );
                window.localStorage.setItem("f_name", this.state.firstname);
                window.localStorage.setItem("city", this.state.city);
                window.localStorage.setItem("dob", this.state.dob);
                window.localStorage.setItem("gender", this.state.gender);
                
                window.location.href = `/thankyou/${this.state.patient_id}?embed=true`;
              } else {
                window.location.href = `/thankyou/${this.state.patient_id}?embed=true`;
              }
            }
          }
        );
      })
      .catch((errSuccessAck) => {
        //log(`${errSuccessAck}, "error while acknowledgement"`);
        this.setState({
          isParticipantEnrolled: false,
          showModal: false,
          showAcknowledgement: false,
        });
      });
  };

  componentDidMount() {
    let { studyId } = this.props.match.params;
    let decodedString = decodeURIComponent(this.props.location.pathname);
    let decodeSearchString = decodeURIComponent(this.props.location.search);

    let city = window.sessionStorage.getItem("city");
    let state = window.sessionStorage.getItem("state");
    let country = window.sessionStorage.getItem("country");
    let doc_upload_status = window.localStorage.getItem("doc_upload_status");
    if (doc_upload_status !== null) {
      this.setState({ showPrescreening: false });
      let details = JSON.parse(doc_upload_status);
      this.setState({
        country: details.country,
        city: details.city,
        zipcode: details.zipcode,
        firstname: details.firstname,
        lastname: details.lastname,
        email: details.email,
        gender: details.gender,
        dob: details.dob,
        phone: details.phone,
        conditions: details.conditions,
        medications: details.medications,
        primarylanguage: details.primarylanguage,
      });
    } else {
      this.setState({
        city: city === null ? "" : city,
        state: state === null ? "" : state,
        country:
          country === "" ||
          country === undefined ||
          country === null ||
          country === "US" ||
          country === "United States of America" ||
          country === "United States Of America" ||
          country === "UNITED STATES OF AMERICA"
            ? "UNITED STATES"
            : country,
      });
      // log(
      //   `${localStorage.getItem("criteria")},${localStorage.getItem(
      //     "currentPage"
      //   )}, "checking localstorage for criteria"`
      // );
      let criteria = localStorage.getItem("criteria");
      let current_page = localStorage.getItem("currentPage");
      if (criteria !== undefined || criteria !== null) {
        this.setState({ criteria: criteria, currentpage: current_page });
      } else {
        this.setState({ currentpage: current_page });
      }
      this.setState(
        {
          studyId: studyId,
        },
        () => {
          window.localStorage.clear();
          window.localStorage.removeItem("twilio-flex-cf");
          window.localStorage.removeItem("loglevel:twilio-sync");
          window.localStorage.removeItem("loglevel:twilsock");
          window.localStorage.removeItem("loglevel:twilio-flex-webchat-ui");
          window.localStorage.removeItem("loglevel");
          window.localStorage.removeItem("loglevel:twilio-chat");
          window.localStorage.removeItem("study_nct")
          window.localStorage.removeItem("study_sid")
          window.localStorage.removeItem("acc_sid");
          window.localStorage.removeItem("pt_sys_id");
          window.localStorage.removeItem("flow_id");
          window.localStorage.removeItem("study_nct")
                  window.localStorage.removeItem("study_sid")
          window.sessionStorage.clear();
          this.getTrialUsingStudyId();
        }
      );
    }
  }

  componentWillUnmount() {
    this.setState({ participant_id: "" });
  }

  onCloseModal = () =>{
    this.setState({loadingForModal: false, showAcknowledgement: false})
  }

  getTrialUsingStudyId = () => {
    getTrialId(this.state.studyId)
      .then((gotStudy) => {
        // log(
        //   `${gotStudy},
        //   "got required study with id and type during enroll"`
        // );
        this.setState(
          {
            trial: gotStudy && gotStudy.trial,
          },
          () => {
            this.setState(
              {
                domain: this.state.trial && this.state.trial.domain,
                studySysId: this.state.trial && this.state.trial.study_sys_id,
                nctNumber: this.state.trial && this.state.trial.nct_number,
                show_patient_id:
                  (this.state.trial && this.state.trial.show_patient_id) ||
                  "false",
              },
              () => {
                window.localStorage.setItem("nctId", this.state.nctNumber);
                if (
                  this.state.trial &&
                  this.state.trial.account_sid &&
                  this.state.trial.account_sid !== undefined &&
                  this.state.trial &&
                  this.state.trial.account_sid &&
                  this.state.trial.account_sid.length > 0
                ) {
                  window.localStorage.setItem("study_nct", this.state.trial && this.state.trial.nct_number)
                  window.localStorage.setItem("study_sid", this.state.trial && this.state.trial.study_sys_id)
                  window.localStorage.setItem(
                    "acc_sid",
                    this.state.trial && this.state.trial.account_sid
                  );
                  window.localStorage.setItem(
                    "flow_id",
                    this.state.trial && this.state.trial.flex_flow_id
                  );
                }
                getQuestionGroup(this.state.studySysId, this.state.studyId)
                  .then((qG) => {
                    //log(`${qG}, "qG"`);
                    this.setState({ questions: qG && qG.questions }, () => {
                      if (
                        this.state.questions &&
                        this.state.questions.length > 0
                      ) {
                        this.setState({
                          isLoading: true,
                          //participant_id: Date.now()+"-"+ this.getRandomNumber() ,
                          showPrescreening: true,
                        });
                      } else {
                        this.setState({
                          isLoading: true,
                          //participant_id: "",
                          showPrescreening: false,
                          showRegisterOptions: true,
                        });
                      }
                    });
                  })
                  .catch((errQG) => {
                    //log(`${errQG}, "errQG"`);
                    this.setState({ questions: [], isLoading: true });
                  });
              }
            );
          }
        );
      })
      .catch((errGotStudy) => {
        // log(
        //   `${errGotStudy},
        //   "cannot get study details using trial id and type during enroll"`
        // );
        this.setState(
          {
            trial: errGotStudy && errGotStudy.trial,
            showRequirements: errGotStudy && errGotStudy.showRequirements,
          },
          () => {
            this.setState({
              domain: this.state.trial && this.state.trial.domain,
            });
          }
        );
      });
  };

  render() {
    const { isParticipantEnrolled } = this.state;
    //const {study_phase,brief_title} = this.state.trial
    return (
      <div>
        <div
          style={{backgroundColor : "#c9d6d9"}}
          className={`${
            this.props.location &&
            this.props.location.search &&
            this.props.location.search.indexOf("embed=true") !== -1
              ? ""
              : "p-0 pt-5 mt-4"
          }`}
        >
          {/* <Warning /> */}
          <Participant
            {...this.state}
            navigateOption={this.navigateOption}
            disabledTrials={disabledPromoTrials}
            onTextInputChange={this.onTextInputChange}
            onPhoneNumChange = {this.onPhoneNumChange}
            onRaceCheckboxChange={this.onRaceCheckboxChange}
            onTermsCheckboxChange={this.onTermsCheckboxChange}
            onCloseModal={this.onCloseModal}
            showTerms={this.showTerms}
            onRecaptchaChange={this.onRecaptchaChange}
            optionChange={this.optionChange}
            scrollToField={this.scrollToField}
            signUp={this.signUp}
            onEnroll={this.onEnroll}
            onAcknowledge={this.onAcknowledge}
            hideSteps={
              this.props.location &&
              this.props.location.search &&
              this.props.location.search.indexOf("embed=true") !== -1
                ? true
                : false
            }
          />
        </div>
      </div>
    );
  }
}

export default EnrollmentController;
