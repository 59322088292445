import React from "react";
import { getSuggestions } from "../../helpers";
import { log } from "../../helpers/helper";
import { checkPatientEMRUser, fetchHealthSystems } from "../../helpers/pthelper";
import { thirdStep } from "../theme/icons";
import HomeView from "./HomeView";
import axios from "axios";

class HomeController extends React.Component {
  constructor(props) {
    super();
    this.item = window.localStorage.getItem("twilio-flex-cf");
    log(this.item , 'item');
    window.localStorage.removeItem("twilio-flex-cf");
    log(this.item , 'after removing')
    this.state = {
      condition: "",
      conditionError: false,
      country: "UNITED STATES",
      suggestions:[],
      cursor:0,
    };
  }

  handleChange = (e) => {
    //log(e.target.name,e.target.value , 'checking inputs');
    this.setState({ [e.target.name]: e.target.value, cursor: 0 },()=>{
      if(this.state.condition && this.state.condition.length > 0){
        getSuggestions(this.state.condition).then(gotSuggestions=>{
          //log(gotSuggestions,'got suggestions');
          if(gotSuggestions && gotSuggestions.length > 0){
          this.setState({ suggestions: gotSuggestions})
          }else{
            this.setState({ suggestions: [],cursor: 0})
          }
        }).catch(errSuggestions=>{
          //log(errSuggestions,'got suggestions');
          this.setState({ suggestions: [],cursor: 0})
        })
      }else{
        this.setState({ suggestions : [],cursor: 0})
      }
    });
  };

  handleSearchKeyDown=(e)=>{
    if(e.key === "ArrowDown"){
      let previous_cursor = this.state.cursor
      if(previous_cursor === this.state.suggestions.length){
        this.setState({cursor: previous_cursor},()=>{
          this.setState({ condition: this.state.suggestions[this.state.cursor - 1]})
        })
      }else{
        this.setState({cursor: previous_cursor + 1},()=>{
          this.setState({ condition: this.state.suggestions[this.state.cursor - 1]})
        })
      }
    }else if(e.key === "ArrowUp"){
      let previous_cursor = this.state.cursor;
      if(previous_cursor === 1){
        this.setState({ cursor: previous_cursor},()=>{
          this.setState({ condition: this.state.suggestions[this.state.cursor - 1]})
        })
      }else{
        this.setState({ cursor: previous_cursor - 1},()=>{
          this.setState({ condition: this.state.suggestions[this.state.cursor - 1]})
        })
      }
    } else if (e.key === "Enter") {
      this.checkInputs();
    }
  }

  sendInfo = () => {
    this.checkInputs();
  };

  checkInputs = () => {
    const { condition = "", country = "" } = this.state;
    const { history } = this.props;
    if (condition === "" || country === "") {
      // alert(
      //   "Please enter search condition and select country to get clinical trials"
      // );
      if(condition === ""){
      this.setState({conditionError : true})
      } else if(country === "" || " "){
        this.setState({countryError: true})
      } else if( condition === "" && country === ""){
        this.setState({conditionError : true, countryError: true})
      }
    } else {
      history.push(`/results?q=${condition}&in=${country}`);
    }
  };

  setCondition=(condition)=>{
    this.setState({
      condition:condition, suggestions:[]
    })
  }

  componentDidMount (){
        window.localStorage.clear()
        // window.localStorage.removeItem("twilio-flex-cf")
        // window.localStorage.removeItem("prescreening");
        // window.localStorage.removeItem('loglevel:twilio-sync')
        // window.localStorage.removeItem('loglevel:twilsock')
        // window.localStorage.removeItem('loglevel:twilio-flex-webchat-ui')
        // window.localStorage.removeItem('loglevel')
        // window.localStorage.removeItem('loglevel:twilio-chat')
        // window.localStorage.removeItem("chat_status" );
        // window.localStorage.removeItem("prospectID" );
        // window.localStorage.removeItem("domainID" );
        // window.localStorage.removeItem("emr_user" );
        // window.localStorage.removeItem("records-status" );
        // window.localStorage.removeItem("studyID" );
        // window.localStorage.removeItem("nct_id" );
        // window.localStorage.removeItem("f_name");
        // window.localStorage.removeItem("city");
        // window.localStorage.removeItem("dob");
        // window.localStorage.removeItem("gender");
        window.sessionStorage.clear();
  }

  render() {
    return (
      <div>
        <HomeView
          handleChange={this.handleChange}
          handleSearchKeyDown={this.handleSearchKeyDown}
          setCondition={this.setCondition}
          sendInfo={this.sendInfo}
          {...this.state}
          hideSpacing={`${
            this.props.location &&
            this.props.location.search &&
            this.props.location.search.indexOf("embed=true") !== -1
              ? "row"
              : "row pt-5 mt-5"
          }`}
        />
      </div>
    );
  }
}

export default HomeController;
