import React from "react";
import { AcknowledgementModal } from "../features/enroll";
import { countries } from "../helpers";
import { Recaptcha_site_key, environment } from "../../src/constants";
import ReCAPTCHA from "react-google-recaptcha";

const ParticipantModal = (props) => {
  let { signUp, showParticipantModal, changeOption, onAcknowledge ,
    showAcknowledgement, loadingForModal ,loadingForParticipantModal, onTextInputChange, onRaceCheckboxChange , fields } = props;
    let {
        country,
        city,
        zipcode,
        firstname,
        lastname,
        email,
        gender,
        dob,
        phone,
        smokingstatus,
        primarylanguage,
        patient_id,
        currentmedication,
        healthcondition,
        familyhistory,
        recaptcha_response,
        onRecaptchaChange
    } = fields
  return (
    <div className="acknowledgement_form container text-left p-0 pt-3">
      <div className="d-flex align-items-center justify-content-end">
        <button
          onClick={() => changeOption(true)}
          style={{ backgroundColor: "#BC5405" }}
          className="next btn btn-danger btn-lg"
        >
          Next
        </button>
      </div>
      <div
        style={{ display: showParticipantModal ? "block" : "none" }}
        className="modal p-0 pt-5"
      >
        <div className="modal-content">
          <div className="modal-header">
                  <button onClick={()=>changeOption(false)} type="button" className="close">&times;</button>
                </div>
          {loadingForParticipantModal === true ? (
            <div className="acknowledgement_form container">
              <div>
                  <div className="row my-5">
                    <div className="col-sm-12 col-md-12 col-xs-12 col-lg-4 px-0">
                      <div className="textinput form-group">
                        <p>{"Country*"}</p>
                        <select
                          onChange={onTextInputChange}
                          className="p-2 form-control"
                          placeholder={"Select"}
                          name={"country"}
                          value={country}
                        >
                          {countries.map((nation, i) => (
                            <option key={i} value={nation}>
                              {nation}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-12 col-xs-12 col-lg-4 px-0">
                      <div className="textinput form-group">
                        <p>{"City*"}</p>
                        <input
                          type="text"
                          className="p-2 form-control"
                          name={"city"}
                          onChange={onTextInputChange}
                          value={city}
                          placeholder={"Enter"}
                        ></input>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-12 col-xs-12 col-lg-4 px-0">
                      <div className="textinput form-group">
                        <p>{"Zipcode*"}</p>
                        <input
                          type="number"
                          className="p-2 form-control"
                          name={"zipcode"}
                          onChange={onTextInputChange}
                          value={zipcode}
                          placeholder={"Enter"}
                        ></input>
                      </div>
                    </div>
                  </div>
                  <div className="row my-5">
                    <div className="col-sm-12 col-md-12 col-xs-12 col-lg-4 px-0">
                      <div className="textinput form-group">
                        <p>{"First Name*"}</p>
                        <input
                          type="text"
                          className="p-2 form-control"
                          name={"firstname"}
                          onChange={onTextInputChange}
                          value={firstname}
                          placeholder={"Enter"}
                        ></input>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-12 col-xs-12 col-lg-4 px-0">
                      <div className="textinput form-group">
                        <p>{"Last Name*"}</p>
                        <input
                          type="text"
                          className="p-2 form-control"
                          name={"lastname"}
                          onChange={onTextInputChange}
                          value={lastname}
                          placeholder={"Enter"}
                        ></input>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-12 col-xs-12 col-lg-4 px-0">
                      <div className="textinput form-group">
                        <p>{"Email*"}</p>
                        <input
                          type="email"
                          className="p-2 form-control"
                          name={"email"}
                          onChange={onTextInputChange}
                          value={email}
                          placeholder={"Enter"}
                        ></input>
                        {/* {showEmailError ? (
                          <p style={{ color: "red" }} className="mb-0">
                            Please enter valid email
                          </p>
                        ) : null} */}
                      </div>
                    </div>
                  </div>
                  <div className="row my-5">
                    <div className="col-sm-12 col-md-12 col-xs-12 col-lg-4 px-0">
                      <div className="textinput form-group">
                        <p>{"Gender*"}</p>
                        <select
                          onChange={onTextInputChange}
                          className="pl-2 form-control"
                          placeholder={"Select"}
                          name={"gender"}
                          value={gender}
                        >
                          {[
                            { title: "Please Select", value: " " },
                            { title: "Male", value: "male" },
                            { title: "Female", value: "female" },
                          ].map((selectoption, i) => (
                            <option key={i} value={selectoption.value}>
                              {selectoption.title}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-12 col-xs-12 col-lg-4 px-0">
                      <div className="textinput form-group">
                        <p>{"Date of Birth*"}</p>
                        <input
                          type="date"
                          className="p-2 form-control"
                          name={"dob"}
                          onChange={onTextInputChange}
                          value={dob}
                          placeholder={"DD/MM/YYYY"}
                        ></input>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-12 col-xs-12 col-lg-4 px-0">
                      <div className="textinput form-group">
                        <p>{"Primary Contact Number*"}</p>
                        <input
                          type="number"
                          className="p-2 form-control"
                          name={"phone"}
                          onChange={onTextInputChange}
                          value={phone}
                          placeholder={"Enter"}
                        ></input>
                      </div>
                    </div>
                  </div>
                  <div className="row my-3">
                    <div className="col-sm-12 col-md-12 col-xs-12 col-lg-4 px-0">
                      <div className="textinput mt-2 form-group">
                        <p>{"Smoking Status*"}</p>
                        <select
                          onChange={onTextInputChange}
                          className="p-2 form-control"
                          placeholder={"Select"}
                          name={"smokingstatus"}
                          value={smokingstatus}
                        >
                          {[
                            { title: "Please Select", value: " " },
                            { title: "Current", value: "Current" },
                            { title: "Ex", value: "Ex" },
                            { title: "Never", value: "Never" },
                          ].map((selectoption, i) => (
                            <option key={i} value={selectoption.value}>
                              {selectoption.title}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-12 col-xs-12 col-lg-4 px-0">
                      <div className="textinput mt-2 form-group">
                        <p>{"Primary Language*"}</p>
                        <select
                          onChange={onTextInputChange}
                          className="p-2 form-control"
                          placeholder={"Select"}
                          name={"primarylanguage"}
                          value={primarylanguage}
                        >
                          {[
                            { title: "Please Select", value: " " },
                            { title: "English", value: "en" },
                            { title: "Spanish", value: "sp" },
                            { title: "Vietnamese", value: "vt" },
                          ].map((selectoption, i) => (
                            <option key={i} value={selectoption.value}>
                              {selectoption.title}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    {/* {show_patient_id === "true" ? 
                      <div className="col-sm-12 col-md-12 col-xs-12 col-lg-4 px-0">
                          <div className="textinput mt-2 form-group">
                            <p>{"Patient ID"}</p>
                            <input
                            type = "text"
                              onChange={onTextInputChange}
                              className="p-2 form-control"
                              placeholder={"Enter Patient ID"}
                              name={"patient_id"}
                              value={patient_id}
                            >
                            </input>
                          </div>
                    </div>
                    : null} */}
                  </div>
                  <div className="textinput my-3">
                    <p className="mb-0">
                      How do you identify your race or ethnicity? *
                    </p>
                    {[
                      {
                        title: "American Indian or Alaskan Native",
                        value: "American Indian or Alaskan Native",
                      },
                      {
                        title: "Asian or Pacific Islander",
                        value: "Asian or Pacific Islander",
                      },
                      {
                        title: "Black or African American",
                        value: "Black or African American",
                      },
                      {
                        title: "Hispanic or Latino",
                        value: "Hispanic or Latino",
                      },
                      {
                        title: "White or Caucasian",
                        value: "White or Caucasian",
                      },
                      {
                        title: "(Mixed Race) American Indian or Alaskan Native",
                        value: "(Mixed Race) American Indian or Alaskan Native",
                      },
                      {
                        title: "(Mixed Race) Asian or Pacific Islander",
                        value: "(Mixed Race) Asian or Pacific Islander",
                      },
                      {
                        title: "(Mixed Race) Black or African American",
                        value: "(Mixed Race) Black or African American",
                      },
                      {
                        title: "(Mixed Race) Hispanic or Latino",
                        value: "(Mixed Race) Hispanic or Latino",
                      },
                      {
                        title: "(Mixed Race) White or Caucasian",
                        value: "(Mixed Race) White or Caucasian",
                      },
                    ].map((raceItem, i) => (
                      <div key={i} className="promo_answer">
                        <input
                          style={{ height: "auto", width: "auto" }}
                          className="mx-1 mt-2"
                          type={"checkbox"}
                          onChange={(e) =>
                            onRaceCheckboxChange(e, raceItem.title)
                          }
                          name={raceItem.value}
                          value={raceItem.value}
                        />
                        <span className="mx-2">{raceItem.title}</span>
                      </div>
                    ))}
                  </div>
                  <div className="">
                    <div className="pl-0">
                      <div className="textinput form-group">
                        <p>{"Current Medication"}</p>
                        <textarea
                          className="col-12 p-2 form-control"
                          type="text"
                          name={"currentmedication"}
                          onChange={onTextInputChange}
                          value={currentmedication}
                          placeholder={"Enter"}
                        ></textarea>
                      </div>
                    </div>
                    <div className="pl-0">
                      <div className="textinput form-group">
                        <p>{"Health Condition"}</p>
                        <textarea
                          className="col-12 p-2 form-control"
                          type="text"
                          name={"healthcondition"}
                          onChange={onTextInputChange}
                          value={healthcondition}
                          placeholder={"Enter"}
                        ></textarea>
                      </div>
                    </div>
                    <div className="pl-0">
                      <div className="textinput form-group">
                        <p>{"Relevant Family History"}</p>
                        <textarea
                          className="col-12 p-2 form-control"
                          type="text"
                          name={"familyhistory"}
                          onChange={onTextInputChange}
                          value={familyhistory}
                          placeholder={"Enter"}
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <button 
                    onClick={()=>signUp()} 
                    className="btn btn-sm btn-primary"
                >
                    Complete Sign Up
                </button> */}
                <div>
                      <ReCAPTCHA
                        sitekey={Recaptcha_site_key}
                        onChange={onRecaptchaChange}
                        // ref={ref}
                      />
                  </div>
                  {recaptcha_response === "" ? <p style={{color: 'red', fontSize: 13}}>Please fill recaptcha</p> : null}
                <div className="my-2 d-flex align-items-center justify-content-end">
                    <AcknowledgementModal
                      loadingForModal={loadingForModal}
                      //onCloseModal={onCloseModal}
                      signUp={signUp}
                      onAcknowledge={onAcknowledge}
                      showAcknowledgement={showAcknowledgement}
                    />
                </div>
            </div>
          ) : (
            <h3 className="mx-auto text-center mb-0">loading ...</h3>
          )}
        </div>
      </div>
    </div>
  );
};

export default ParticipantModal;
