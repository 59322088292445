import React, { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import NCT04232280 from "./NCT04232280";
import NCT03095066 from "./NCT03095066";
import NCT03043313 from "./NCT03043313";
import NCT03896945 from "./NCT03896945";
import NCT04528719 from "./NCT04528719";
import NCT04053634 from "./NCT04053634";
import NCT04133909 from "./NCT04133909";
import NCT01716806 from "./NCT01716806";
import NCT03288545 from "./NCT03288545";
import NCT04860297 from "./NCT04860297";
import NCT04538989 from "./NCT04538989";
import NCT04144348 from "./NCT04144348";
import NCT04813796 from "./NCT04813796";
import NCT04628481 from "./NCT04628481";
import NCT04956575 from "./NCT04956575";
import NCT04695977 from "./NCT04695977";
import NCT04611802 from "./NCT04611802";
import NCT03897881 from "./NCT03897881";
import { log } from "../../helpers/helper";
import { getMultipleSites } from "../../helpers";
import {
  Loading,
  RecruitmentSteps,
  ScreenFlow,
  DataNotFound,
} from "../../components";
import { thirdStep } from "../theme/icons";

const PromoTrials = (props) => {
  const { nctid } = props;
  const location = useLocation;
  log(nctid + "promo nctid");
  const [study, setStudy] = useState("");
  const [isLoading, setLoading] = useState(false);

  const flowarray = (nctid) => {
    return [
      { title: "Home", link: "/" },
      { title: "Find Trials", link: "/#find_trial" },
      {
        title: "Search Results",
        link: `/results?q=${nctid}&in=UNITED%20STATES`,
      },
      {
        title: "Trial Details",
        link: `/study/${nctid}`,
      },
    ];
  };

  useEffect(() => {
    window.localStorage.removeItem("chat_status");
    window.localStorage.removeItem('acc_sid')
        window.localStorage.removeItem('flow_id')
        window.localStorage.removeItem("pt_sys_id");
        window.localStorage.removeItem("study_nct")
        window.localStorage.removeItem("study_sid")
    window.localStorage.removeItem("prospectID")
    getMultipleSites(nctid)
      .then((nctStudy) => {
        log(nctStudy, "got nct study");
        if (nctStudy && nctStudy.length > 0) {
          let filterCentral = nctStudy.filter(study => study.central === "true")
          if(filterCentral && filterCentral.length > 0){
            setStudy(filterCentral[0]);
          }else{
            setStudy(nctStudy[0])
          }
          setLoading(true);
        } else {
          setStudy(null);
          setLoading(true);
        }
      })
      .catch((errNctStudy) => {
        //log(errNctStudy, "err with fetching nct study");
        setStudy(null);
        setLoading(true);
      });
  }, [nctid]);

  const openNewTab = (url) => {
     setTimeout(()=>{
      window.open(study.embedded_link , '_self')
    },1500)
  };

  const splitQueryParams = (url) =>{
    if(url && url.length > 0 ){
      let result = url.split("?")
      if(result && result.length > 0){
        return result[0]
      } else{
        log(result, 'no query params found');
        return result
      }
    } else{
      log(url, 'no url found')
    }
  }

  return (
    <div>
      {isLoading === true ? (
        nctid && nctid.length > 0 ? (
          <div>
            {study && study.embedded_link && study.embedded_link.length > 0 ? (
              <div>
                {/* {location &&
                location.search &&
                location.search.indexOf("embed=true") !== -1 ? null : (
                  <>
                    <RecruitmentSteps step={"Review Trials"} />
                    <div className="container d-flex align-items-center justify-content-start">
                      <ScreenFlow flow={flowarray(nctid)} />
                    </div>
                  </>
                )} */}
                <div className="container">
                  {study &&
                  study.embedded_link &&
                  study.embedded_link.length > 0 ? (
                    <div>
                      <iframe
                        style={{ height: nctid === "NCT04221230" ? 7000 : 4000, width: "100%", border: "none" }}
                        title={nctid}
                        src={study.embedded_link}
                      ></iframe>
                        {/* <div className="forEmbed" onClick={()=> window.open(splitQueryParams(study.embedded_link) + "/prescreener" , "_blank")} style={{ 
                          height: nctid === "NCT04221230" ? 7000 : 4000,
                          backgroundColor: 'transparent',
                          position: 'absolute',
                          cursor : 'pointer'
                      }}></div> */}

                    </div>
                  ) : (
                    <h6 className="mb-0 text-center my-5 py-5">loading ...</h6>
                  )}
                </div>
              </div>
            ) : (
              <div>
                {(nctid === "NCT04611802" && <NCT04611802 />) ||
                (nctid === "NCT04232280" && <NCT04232280 />) ||
                (nctid === "NCT03897881" && <NCT03897881 />) ||
                (nctid === "NCT03896945" && <NCT03896945 />) ||
                (nctid === "NCT03043313" && <NCT03043313 />) ||
                (nctid === "NCT03095066" && <NCT03095066 />) ||
                (nctid === "NCT04528719" && <NCT04528719 />) ||
                (nctid === "NCT04053634" && <NCT04053634 />) ||
                (nctid === "NCT04133909" && <NCT04133909 />) ||
                (nctid === "NCT01716806" && <NCT01716806 />) ||
                (nctid === "NCT04860297" && <NCT04860297 />) ||
                (nctid === "NCT03288545" && <NCT03288545 />) ||
                (nctid === "NCT04538989" && <NCT04538989 />) ||
                (nctid === "NCT04144348" && <NCT04144348 />) ||
                (nctid === "NCT04813796" && <NCT04813796 />) ||
                (nctid === "NCT04628481" && <NCT04628481 />) ||
                (nctid === "NCT04956575" && <NCT04956575 />) ||
                (nctid === "NCT04695977" && <NCT04695977 />) ||
                study === null ? (
                  <DataNotFound message={"Study details not found"} />
                ) : (
                  <Loading />
                )}
              </div>
            )}
          </div>
        ) : // <div className="d-flex align-items-center justify-content-center p-5 m-5">
        //   <h6 className="mt-5 pt-5">{study === null ? "Study details not found" : <Loading />}</h6>
        // </div>
        study === null ? (
          <DataNotFound message={"Study details not found"} />
        ) : (
          <Loading />
        )
      ) : (
        <Loading />
      )}
    </div>
  );

  //  if (nctid && nctid.length > 0) {
  //     if (study && study.embedded_link && study.embedded_link.length > 0) {
  //       return (
  //         <div>
  //           {location &&
  //           location.search &&
  //           location.search.indexOf("embed=true") !== -1 ? null : (
  //             <>
  //               <RecruitmentSteps step={"Review Trials"} />
  //               <div className="container d-flex align-items-center justify-content-start">
  //                 <ScreenFlow flow={flowarray(nctid)} />
  //               </div>
  //             </>
  //           )}
  //           <div className="container">
  //             {study && study.embedded_link && study.embedded_link.length > 0 ? (
  //               <iframe
  //                 style={{ height: 3000, width: "100%", border: "none" }}
  //                 title={nctid}
  //                 src={study.embedded_link}
  //               ></iframe>
  //             ) : (
  //               <h6 className="mb-0 text-center my-5 py-5">loading ...</h6>
  //             )}
  //           </div>
  //         </div>
  //       );
  //     } else {
  //       if (nctid === "NCT04611802") {
  //         return (
  //           <div>
  //             <NCT04611802 />
  //           </div>
  //         );
  //       } else if (nctid === "NCT04232280") {
  //         return (
  //           <div>
  //             <NCT04232280 />
  //           </div>
  //         );
  //       } else if (nctid === "NCT03897881") {
  //         return (
  //           <div>
  //             <NCT03897881 />
  //           </div>
  //         );
  //       } else if (nctid === "NCT03896945") {
  //         return (
  //           <div>
  //             <NCT03896945 />
  //           </div>
  //         );
  //       } else if (nctid === "NCT03043313") {
  //         return (
  //           <div>
  //             <NCT03043313 />
  //           </div>
  //         );
  //       } else if (nctid === "NCT03095066") {
  //         return (
  //           <div>
  //             <NCT03095066 />
  //           </div>
  //         );
  //       } else if (nctid === "NCT04528719") {
  //         return (
  //           <div>
  //             <NCT04528719 />
  //           </div>
  //         );
  //       } else if (nctid === "NCT04053634") {
  //         return (
  //           <div>
  //             <NCT04053634 />
  //           </div>
  //         );
  //       } else if (nctid === "NCT04133909") {
  //         return (
  //           <div>
  //             <NCT04133909 />
  //           </div>
  //         );
  //       } else if (nctid === "NCT01716806") {
  //         return (
  //           <div>
  //             <NCT01716806 />
  //           </div>
  //         );
  //       } else if (nctid === "NCT04860297") {
  //         return (
  //           <div>
  //             <NCT04860297 />
  //           </div>
  //         );
  //       } else if (nctid === "NCT03288545") {
  //         return (
  //           <div>
  //             <NCT03288545 />
  //           </div>
  //         );
  //       } else if (nctid === "NCT04538989") {
  //         return (
  //           <div>
  //             <NCT04538989 />
  //           </div>
  //         );
  //       } else if (nctid === "NCT04144348") {
  //         return (
  //           <div>
  //             <NCT04144348 />
  //           </div>
  //         );
  //       } else if (nctid === "NCT04813796") {
  //         return (
  //           <div>
  //             <NCT04813796 />
  //           </div>
  //         );
  //       } else if (nctid === "NCT04628481") {
  //         return (
  //           <div>
  //             <NCT04628481 />
  //           </div>
  //         );
  //       } else if (nctid === "NCT04956575") {
  //         return (
  //           <div>
  //             <NCT04956575 />
  //           </div>
  //         );
  //       } else if (nctid === "NCT04695977") {
  //         return (
  //           <div>
  //             <NCT04695977 />
  //           </div>
  //         );
  //       }
  //     }
  //   } else if (nctid === " " || nctid === null || nctid === undefined) {
  //     return (
  //       <div className="p-5 m-5 mx-auto text-center">
  //         <p>Page not found</p>
  //       </div>
  //     );
  //   } else {
  //     return (
  //       <div className="p-5 m-5 mx-auto text-center">
  //         <h3>Page not found</h3>
  //       </div>
  //     );
  //   }
};

export default PromoTrials;
