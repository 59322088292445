import React from "react";

const Agent = () =>{
    return(
        <div>
            <div className="">
            <iframe style={{ height : 900 , width : '100%'}} src="https://flex.twilio.com/agent-desktop/" title="agent page"></iframe>
            </div>
        </div>
    )
}

export default Agent