import React from "react";
import "../App.css";
import "../bootstrap.css";
import { environment } from "../constants";
import {
  logo,
  facebookIcon,
  twitterIcon,
  linkedinIcon,
  mappin,
  iLogo,
  footerILogo,
  i_fb,
  i_twitter,
  i_linkedin,
} from "../features/theme/icons";

const Footer = (props) => {
  const { showFooter } = props;
  return (
    <div style={{backgroundColor: 'black'}} className="footer p-0 container-fluid">
      {showFooter === false ? null : (
        <div className="container p-0">
          <div className="row">
            <div className="col-sm-12 col-md-3 col-lg-3 pl-2 mt-5">
              <a href={"/"}>
                <div style={{backgroundColor: 'dimgray', width : '80%' }}>
                  <img alt="logo" style={{height: 70}}  src={logo}></img>
                </div>
              </a>
              {/* <p
                style={{
                  margin: "10px 0px 0px 0px",
                  fontSize: "14px",
                  color: "white",
                  fontWeight: "bold",
                  fontFamily: "NunitoThin",
                }}
              >
                Patient Centric Platform for Clinical Trials
              </p> */}
              <div className=" mt-5 row d-flex align-items-center justify-content-left">
                <a
                  style={{ textDecoration: "none" }}
                  className="mr-3"
                  rel="noopener noreferrer"
                  target="_blank"
                  href={"https://www.facebook.com/patientrials"}
                >
                  <img
                    style={{ height: "30px", width: "30px"}}
                    alt="facebook_icon"
                    src={i_fb}
                  ></img>
                </a>
                <a
                  style={{ textDecoration: "none" }}
                  className="mr-3"
                  rel="noopener noreferrer"
                  target="_blank"
                  href={"https://twitter.com/patientrials"}
                >
                  <img
                    style={{ height: "30px", width: "30px" }}
                    alt="twitter_icon"
                    src={i_twitter}
                  ></img>
                </a>
                <a
                  style={{ textDecoration: "none" }}
                  className="mr-3"
                  rel="noopener noreferrer"
                  target="_blank"
                  href={"https://www.linkedin.com/company/patientrials/"}
                >
                  <img
                    style={{ height: "30px", width: "30px"}}
                    alt="linkedin_icon"
                    src={i_linkedin}
                  ></img>
                </a>
              </div>
            </div>
            <div className="findtrials col-sm-4 col-md-2 col-lg-2 mt-5">
              <h2
                style={{
                  fontSize: "14px",
                  fontFamily: "NunitoThin",
                  fontWeight: "bold",
                  color: "white",
                }}
              >
                FIND TRIALS
              </h2>
              <br></br>
              {[
                "COVID-19",
                "Cancer",
                "Cardiopathy",
                "Nephritis",
                "Eczema",
              ].map((link, i) =>
                link === "Chronic Condition" ? (
                  <div>
                    <h3
                      key={i}
                      style={{
                        fontSize: "14px",
                        fontFamily: "NunitoThin",
                        fontWeight: "bold",
                        color: "white",
                      }}
                    >
                      {"Chronic Condition"}
                    </h3>
                    <ul className="pl-4">
                      {[
                        "Diabetes",
                        "Nephrology",
                        "Pulmonology",
                        "Dematology",
                      ].map((subtitle, i) => (
                        <li
                          key={i}
                          style={{
                            fontSize: "14px",
                            fontFamily: "NunitoThin",
                            fontWeight: "bold",
                            color: "white",
                          }}
                        >
                          {subtitle}
                        </li>
                      ))}
                    </ul>
                  </div>
                ) : (
                  <a
                    key={i}
                    href={`/results?q=${link}&in=USA`}
                    style={{ cursor: "pointer", textDecoration: "none" }}
                  >
                    <h3
                      style={{
                        fontSize: "14px",
                        fontFamily: "NunitoThin",
                        fontWeight: "bold",
                        color: "white",
                      }}
                    >
                      {link}
                    </h3>
                  </a>
                )
              )}
            </div>
            <div className="links col-sm-4 col-md-2 col-lg-2 mt-5">
              <h2
                style={{
                  fontSize: "14px",
                  fontFamily: "NunitoThin",
                  fontWeight: "bold",
                  color: "white",
                }}
              >
                LINKS
              </h2>
              <br></br>
              {[
                { title: "Home", url: "/" },
                {
                  title: "Partners",
                  url: "https://patientrials.com/",
                },
                {
                  title: "About Us",
                  url: "https://patientrials.com/about/",
                },
                {
                  title: "Contact Us",
                  url: "https://patientrials.com/request-demo/",
                },
              ].map((link, i) => (
                <a key={i} href={link.url}>
                  <h3
                    style={{
                      fontSize: "14px",
                      fontFamily: "NunitoThin",
                      fontWeight: "bold",
                      color: "white",
                    }}
                  >
                    {link.title}
                  </h3>
                </a>
              ))}
            </div>
            <div className="links col-sm-4 col-md-2 col-lg-2 mt-5">
              <h2
                style={{
                  fontSize: "14px",
                  fontFamily: "NunitoThin",
                  fontWeight: "bold",
                  color: "white",
                }}
              >
                SIGN IN
              </h2>
              <br></br>
              {[
                {
                  title: "Patients",
                  url: `/enroll/${
                    environment === "PROD" ? "STU01009" : "STU0001002"
                  }`,
                },
                {
                  title: "Administrator",
                  url: "https://patientrialsprod.service-now.com/patientrials_u18?id=patientrials_register&sysparm_domain_restore=false&sysparm_stack=no",
                },
              ].map((link, i) => (
                <a key={i} href={link.url}>
                  <h3
                    style={{
                      fontSize: "14px",
                      fontFamily: "NunitoThin",
                      fontWeight: "bold",
                      color: "white",
                    }}
                  >
                    {link.title}
                  </h3>
                </a>
              ))}
            </div>
            <div className="col-sm-12 col-md-8 col-lg-3 mt-5 px-0">
              <div className="row">
                <p
                  className="col-4 mb-0"
                  style={{
                    fontSize: "14px",
                    fontFamily: "NunitoThin",
                    fontWeight: "bold",
                    color: "white",
                  }}
                >
                  EMAIL
                </p>
                <a
                  href="mailto:hello@patientrials.com"
                  style={{
                    fontSize: "14px",
                    color: "white",
                    textDecoration: "underline",
                  }}
                  className="mb-0"
                >
                  hello@patientrials.com
                </a>
              </div>
              <br></br>
              <div className="my-1 text-left d-flex align-items-start justify-content-between">
                <p
                  className="col-4 mb-0"
                  style={{
                    fontSize: "14px",
                    fontFamily: "NunitoThin",
                    fontWeight: "bold",
                    color: "white",
                  }}
                >
                  USA OFFICES
                </p>
                <div>
                  {[
                    "WeWork Legacy West - 7700 Windrose, Plano, TX 75024, USA",
                    "MATTER - 222 Merchandise Mart Plaza, Suite 1230, Chicago, IL 60654, USA",
                  ].map((office, i) => (
                    <div className="mb-3" key={i}>
                      {/* <p
                      style={{
                        padding: "0px",
                        fontSize: "14px",
                        color: "black",
                        fontWeight: "bold",
                      }}
                      className="mb-0 p-0"
                    >
                      {office.split("-")[0]}
                    </p> */}
                      <p
                        style={{
                          padding: "0px",
                          fontSize: "14px",
                          color: "white",
                          fontWeight: "normal",
                        }}
                        className="mb-0 p-0"
                      >
                        {office}
                        <span className="ml-2">
                          <img alt="mapicon" src={mappin}></img>
                        </span>
                      </p>
                    </div>
                  ))}
                </div>
              </div>
              <div className="my-1 text-left d-flex align-items-start justify-content-between">
                <p
                  className="col-4 mb-0"
                  style={{
                    fontSize: "14px",
                    fontFamily: "NunitoThin",
                    fontWeight: "bold",
                    color: "white",
                  }}
                >
                  INDIA OFFICES
                </p>
                <div>
                  {[
                    "#176, 4th Floor, Adarsh Eco Place, KIADB EPIP 2nd phase, Whitefield, Bengaluru, Karnataka 560066, India",
                  ].map((office, i) => (
                    <div key={i}>
                      <p
                        style={{
                          padding: "0px",
                          fontSize: "14px",
                          color: "white",
                          fontWeight: "normal",
                        }}
                        className="mb-0 p-0"
                      >
                        {office}
                        <span className="ml-2">
                          <img alt="mappin" src={mappin}></img>
                        </span>
                      </p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="copyright mx-auto my-3 text-center">
              <h3 style={{color: "white"}}>
                © Copyright 2018 — PatienTrials, a company of PatienTrials Inc.
              </h3>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Footer;
