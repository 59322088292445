import React from "react";
import { PSView, CTView, PtEnrollScreen } from "./trialdetails";
import { withRouter } from "react-router-dom";
import { getTrialId, getLocations } from "../../helpers";
import { DataNotFound, Loading } from "../../components";
import { log } from "../../helpers/helper";

class ReviewTrialController extends React.Component {
  constructor(props) {
    super();
    // this.snow_study = window.sessionStorage.getItem("snow_study_number");
    this.country = window.sessionStorage.getItem("country");
    this.condition = window.sessionStorage.getItem("condition");
    this.state = {
      isLoading: false,
      queryCondition: "",
      queryCountry: "",
      city: "",
      state: "",
      zipcode: "",
      country: "",
      studyId: "",
      trial: null,
      PSTrial: null,
      CSTrial: null,
      type: "",
      partner: "",
      showRequirements: false,
      showMultipleSites: false,
      areCoordinatesReceived: false,
      multipleSites: [],
      coordinates: [],
      userPickedStudyId: "",
      showUpcoming: false,
    };
  }
  onCheckboxChange = (e) => {
    if (e.target.checked) {
      //log(`${e.target.checked},${e.target.value},checking inputs`);
      this.setState({ userPickedStudyId: e.target.value }, () => {
        let userPickedStudy = this.state.multipleSites.filter(
          (site) => site.snow_study_number === this.state.userPickedStudyId
        );
        this.setState({
          trial:
            userPickedStudy && userPickedStudy.length > 0 && userPickedStudy[0],
        });
      });
    }
    //log(this.obj + "in cmv oncheckbox");
  };
  onEnrollForPSLocation = () => {
    const { trial } = this.state;
    this.setState({
      city: trial && trial.city,
      country: trial && trial.country,
      state: trial && trial.state,
      zipcode: trial && trial.pin_code,
      showRequirements: true,
    });
  };
  onEnrollForTSLocation = () => {
    const { trial } = this.state;
    this.setState({
      city: trial && trial.sites && trial.sites[0] && trial.sites[0].city,
      country: trial && trial.sites && trial.sites[0] && trial.sites[0].country,
      state: trial && trial.sites && trial.sites[0] && trial.sites[0].state,
      zipcode: trial && trial.sites && trial.sites[0] && trial.sites[0].zipCode,
      showRequirements: true,
    });
  };

  getStudyLocation = (location, address) => {
    return new Promise((resolve, reject) => {
      getLocations(address)
        .then((gotLocations) => {
          // log(gotLocations.output, "got location");
          resolve({
            location: gotLocations.output.results[0].geometry.location,
            address: gotLocations.output.results[0].formatted_address,
            actual_address: address,
            studyId: location.snow_study_number,
            site_name: location.site_name,
          });
        })
        .catch((errLocation) => {
          //log(errLocation, "err with geocoding");
          reject(null);
        });
    });
  };

  getMultipleLocations = (addresses) => {
    return new Promise((resolve, reject) => {
      let locs = [];
      addresses.map((studyAddress) => {
        let address =
          studyAddress.street_address +
          " " +
          studyAddress.city +
          " " +
          studyAddress.state +
          " " +
          studyAddress.country +
          " " +
          studyAddress.pin_code;
        if (
          studyAddress &&
          studyAddress.active === "true" &&
          studyAddress.promote === "true"
        ) {
          this.getStudyLocation(studyAddress, address)
            .then((gotCoordinates) => {
              // log(gotCoordinates, "got study coordinates");
              locs.push(gotCoordinates);
            })
            .catch((errCoordinates) => {
              log(
                errCoordinates,
                "err with fetching study coordinates"
              );
            });
        } else {
          log(studyAddress, "study is not promoted");
        }
      });
      setTimeout(()=>{
        if (locs && locs.length > 0) {
          resolve(locs);
        } else {
          reject([]);
        }
      },2500)
    });
  };

  // getMultipleLocations = (locations) => {
  //   let locs = [];
  //   return new Promise((resolve,reject)=>{
  //   locations.map((location) => {
  //     let actual_address =
  //       location.street_address +
  //       " " +
  //       location.city +
  //       " " +
  //       location.state +
  //       " " +
  //       location.country +
  //       " " +
  //       location.pin_code;
  //     if (
  //       location &&
  //       location.active === "true" &&
  //       location.promote === "true"
  //     ) {
  //       log(actual_address,'loc address')
  //       getLocations(actual_address)
  //         .then((gotLocations) => {
  //           log(gotLocations, "got location");
  //           locs.push({
  //             location: gotLocations.results[0].geometry.location,
  //             address: gotLocations.results[0].formatted_address,
  //             actual_address: actual_address,
  //             studyId: location.snow_study_number,
  //             site_name: location.site_name,
  //           });
  //         })
  //         .catch((errLocation) => {
  //           //log(errLocation, "err with geocoding");
  //         });
  //     }
  //   });
  //   setTimeout(()=>{
  //     log(locs,'getting locs')
  //   },3500)
  //   // if(locs && locs.length > 0){
  //   //   resolve(locs)
  //   // }else{
  //   //   reject([])
  //   // }
  // })
  // };

  // getCurrentLocation=()=>{
  //   navigator.geolocation.getCurrentPosition((success)=>{
  //     log(success,'geolocation')
  //   })
  // }

  componentDidMount() {
    //log(this.snow_study, "from ");
    window.localStorage.removeItem('twilio-flex-cf')
        window.localStorage.removeItem('loglevel:twilio-sync')
        window.localStorage.removeItem('loglevel:twilsock')
        window.localStorage.removeItem('loglevel:twilio-flex-webchat-ui')
        window.localStorage.removeItem('loglevel')
        window.localStorage.removeItem('loglevel:twilio-chat')
        window.localStorage.removeItem("chat_status");
        window.localStorage.removeItem('acc_sid')
        window.localStorage.removeItem('flow_id')
        window.localStorage.removeItem("pt_sys_id");
        window.localStorage.removeItem("study_nct")
        window.localStorage.removeItem("study_sid")
        window.localStorage.removeItem("prospectID" , this.state.patient_id );
        window.localStorage.removeItem("f_name" , this.state.firstname );
        window.localStorage.removeItem("city" , this.state.city);
        window.localStorage.removeItem("dob" , this.state.dob );
        window.localStorage.removeItem("gender" , this.state.gender );
        window.sessionStorage.clear()
    let { studyId } = this.props.match.params;
    this.setState({
      queryCondition: this.condition,
      queryCountry: this.country,
    });
    this.setState(
      {
        studyId: studyId,
      },
      () => {
        if (studyId === "NCT00876824") {
          //log(studyId,'study id')
          this.setState({ showUpcoming: true, isLoading: true });
        } else {
          this.getTrialUsingStudyId();
        }
        //this.getCurrentLocation()
      }
    );
  }
  
  // fetchInitiallyCreatedTrial = (trials) =>{
  //   let trial_with_actual_dates = trials.map(trial => {
  //     let created = {
  //       ...trial,
  //       actual_date_time : new Date(trial.sys_created_on).getTime()
  //     };
  //     return created
  //   });
  //   // log(trial_with_actual_dates, 'trial_dates');
  //   let trial_dates = trial_with_actual_dates.map(trial => trial.actual_date_time);
  //   let sort_dates = trial_dates.sort();
  //   // log(sort_dates, 'sorted')
  //   let initialTrial = trial_with_actual_dates.filter(trial=> trial.actual_date_time === sort_dates[0]);
  //   if(initialTrial && initialTrial.length > 0){
  //   // log(initialTrial[0], 'initial trial')
  //   return initialTrial[0];
  //   }else{
  //     return trials[0];
  //   }
  // }

  fetchCentralTrial = (trials) =>{
    let centralTrial = trials.filter(trial => trial.central === "true");
    if(centralTrial && centralTrial.length > 0){
      log(centralTrial, 'got central trial');
      return centralTrial[0];
    }else{
      log('err fetching central trial', centralTrial)
      return trials[0];
    }
  }

  getTrialUsingStudyId = () => {
    getTrialId(this.state.studyId)
      .then((gotStudy) => {
        //log(gotStudy, "got required study with id and type");
        if (gotStudy && gotStudy.trial && gotStudy.trial.length > 1) {
          this.setState(
            {
              multipleSites: gotStudy && gotStudy.trial,
              trial : this.fetchCentralTrial(gotStudy && gotStudy.trial),
              // trial: gotStudy && gotStudy.trial[0],
                // this.snow_study === null
                //   ? gotStudy && gotStudy.trial[0]
                //   : gotStudy &&
                //     gotStudy.trial.filter(
                //       (study) => study.snow_study_number === this.snow_study
                //     )[0],
              showMultipleSites: true,
              isLoading: (gotStudy && gotStudy.isLoading) || true,
              partner: gotStudy && gotStudy.partner,
            },
            () => {
              this.getMultipleLocations(gotStudy && gotStudy.trial)
                .then((gotLocs) => {
                  //log(gotLocs, "gotLocs");
                  if (gotLocs && gotLocs.length > 0) {
                    this.setState({ coordinates: gotLocs }, () => {
                      this.setState({ areCoordinatesReceived: true });
                    });
                  } else {
                    this.setState({ coordinates: [] }, () => {
                      this.setState({ areCoordinatesReceived: false });
                    });
                  }
                })
                .catch((errLocs) => {
                  log(errLocs, "errLocs");
                  this.setState({ coordinates: [] }, () => {
                    this.setState({ areCoordinatesReceived: false });
                  });
                });
            }
          );
        } else if (this.state.studyId.startsWith("STU") === true) {
          this.setState({
            trial: gotStudy && gotStudy.trial,
            isLoading: (gotStudy && gotStudy.isLoading) || true,
            partner: gotStudy && gotStudy.partner,
            multipleSites: [],
            userPickedStudyId: "",
            showMultipleSites: false,
          });
        } else {
          this.setState(
            {
              trial:
                (gotStudy && gotStudy.trial[0]) || (gotStudy && gotStudy.trial),
              isLoading: (gotStudy && gotStudy.isLoading) || true,
              partner: gotStudy && gotStudy.partner,
              multipleSites: gotStudy && gotStudy.trial,
              userPickedStudyId: "",
              showMultipleSites: false,
            },
            () => {
              // this.getMultipleLocations([gotStudy && gotStudy.trial[0]])
              //   .then((gotLocs) => {
              //     log(gotLocs, "gotLocs");
              //     this.setState({ coordinates: gotLocs });
              //   })
              //   .catch((errLocs) => {
              //     log(errLocs, "errLocs");
              //   });
            }
          );
        }
      })
      .catch((errGotStudy) => {
        // log(
        //   `${errGotStudy},
        //   "cannot get study details using trial id and type"`
        // );
        this.setState({
          isLoading: (errGotStudy && errGotStudy.isLoading) || true,
          trial: errGotStudy && errGotStudy.trial,
          showRequirements: errGotStudy && errGotStudy.showRequirements,
        });
      });
  };

  renderTrialView = () => {
    const {
      isLoading,
      studyId,
      trial,
      showRequirements,
      partner,
      city,
      state,
      country,
      coordinates,
      zipcode,
      queryCondition,
      queryCountry,
      showMultipleSites,
      multipleSites,
      userPickedStudyId,
      areCoordinatesReceived,
      showUpcoming,
    } = this.state;
    return (
      <div>
        {trial === undefined ||
        trial === null ||
        trial === "" ||
        trial === [] ? (
          <DataNotFound message={"Study details not found"} />
        ) : partner === "patientrials"
        //  && trial.active === "true" &&
        //   trial.promote === "true" 
          ? (
          <PSView
            isLoading={isLoading}
            areCoordinatesReceived={areCoordinatesReceived}
            studyId={studyId}
            trial={trial}
            hideSteps={
              this.props.location &&
              this.props.location.search &&
              this.props.location.search.indexOf("embed=true") !== -1
                ? true
                : false
            }
            showRequirements={showRequirements}
            getTrialUsingStudyId={this.getTrialUsingStudyId}
            studySysId={trial && trial.study_sys_id}
            queryCondition={queryCondition}
            queryCountry={queryCountry}
            city={city}
            state={state}
            coordinates={coordinates}
            country={country}
            zipcode={zipcode}
            onEnrollForLocation={this.onEnrollForPSLocation}
            multipleSites={multipleSites}
            showMultipleSites={showMultipleSites}
            userPickedStudyId={userPickedStudyId}
            onCheckboxChange={this.onCheckboxChange}
          />
        ) : partner === "clinical trials" ? (
          <CTView
            isLoading={isLoading}
            studyId={studyId}
            trial={trial}
            hideSteps={
              this.props.location &&
              this.props.location.search &&
              this.props.location.search.indexOf("embed=true") !== -1
                ? true
                : false
            }
            showRequirements={showRequirements}
            getTrialUsingStudyId={this.getTrialUsingStudyId}
            onEnrollForLocation={this.onEnrollForLocation}
            queryCondition={queryCondition}
            queryCountry={queryCountry}
            city={city}
            state={state}
            country={country}
            zipcode={zipcode}
          />
        ) : (
          <div className="text-center">
            <h2>No Trial Found</h2>
          </div>
        )}
      </div>
    );
  };

  render() {
    const { trial, isLoading, showUpcoming } = this.state;
    return (
      <div
        className={`${
          this.props.location &&
          this.props.location.search &&
          this.props.location.search.indexOf("embed=true") !== -1
            ? ""
            : "pt-5 mt-4"
        }`}
      >
        {/* <div className="container p-0">
            <Warning subtitle={"Please send your feedback to hello@patientrials.com"} />
        </div> */}
        {isLoading ? (
          showUpcoming === true ? (
            <PtEnrollScreen />
          ) : trial === null || trial === undefined || trial === [] ? (
            <DataNotFound message={"Study details not found"} />
          ) : (
            this.renderTrialView()
          )
        ) : (
          <Loading />
        )}
      </div>
    );
  }
}

export default withRouter(ReviewTrialController);
