import React from 'react';
import { useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { checkEMRUser, fetchHealthSystems, fetchRandomHealthSystems, fetchRandomRecords, fetchRecords } from '../helpers/pthelper';
import Loading from './Loading';
import { log } from '../helpers/helper';


class ConnectEMR extends React.Component {
    constructor(props){
        super();
        this.state={
            emr_city : "",
            user_id : "",
            studyId : "",
            healthSystems:"",
            study_sys_id : "",
            domain_id : "",
            isLoading : false,
            userExists : false,
            records : "",
        }
    }

    handleChange = (e) =>{
        this.setState({emr_city : e.target.value})
    }

    generate_random_id = () =>{
        let emr_user = window.localStorage.getItem('emr_user');
        if(emr_user !== null){
            this.setState({ user_id : emr_user },()=>{
            this.getHealthSystems().then(s=>log(s,'got s')).catch(err=>log(err , 'err'))
        })
        }else{
            log(emr_user , 'err fetching existing user');
            let random_id = uuidv4();
        log(random_id , 'random number');
        this.setState({ user_id : random_id },()=>{
            window.localStorage.setItem('emr_user' , random_id)
            this.getHealthSystems().then(s=>log(s,'got s')).catch(err=>log(err , 'err'))
        })
        }
    }

    getHealthSystems = async () => {
        this.setState({ isLoading: false, initiateProcess: false });
        window.localStorage.setItem("city", this.state.city);
        try {
          let healthSystems = await fetchRandomHealthSystems(
            this.state.user_id,
            this.state.emr_city
          );
          log(healthSystems, "got random health systems");
          this.setState({ healthSystems: healthSystems, isLoading: true });
        } catch (errSystems) {
          log(errSystems, "err with health systems");
          this.setState({ healthSystems: [] }, () => {
            this.setState({ isLoading: true });
          });
        }
      };

    HealthSystems = () =>{
      let user_exists = this.state.userExists;
        let city = this.state.emr_city;
        window.localStorage.setItem("records-status" , "fetched");
        window.localStorage.setItem("studyID" , this.state.study_sys_id);
        window.localStorage.setItem("domainID" , this.state.domain_id);
        if(user_exists === true){
          if(city && city.length > 0){
            this.generate_random_id()
          } else{
              log(city , 'no city entered');
              alert("Please enter city name to proceed further. Thank you.")
          }
        } else{
          log(user_exists , 'user not found');
          alert("Error while fetching health systems")
        }
    }

    componentDidMount(){
      let params = this.props.match.params;
      let  { randomID , study_sys_id , domain_id, study_number } = params
      log(randomID , 'params')
      if(randomID && randomID.length > 0){
        this.setState({ 
          user_id : randomID,
          study_sys_id : study_sys_id,
          domain_id : domain_id,
          studyId : study_number
         },()=>{
          window.localStorage.setItem('emr_user', randomID);
          checkEMRUser(this.state.user_id).then(user=>{
            log(user , 'checking user');
            this.setState({ userExists : true },()=>{
              let recordsStatus = window.localStorage.getItem("records-status");
              if(recordsStatus === "fetched"){
                window.location.href = `/health-records/${this.state.user_id}/${this.state.study_sys_id}/${this.state.domain_id}/${this.state.studyId}`
              }
            })
          }).catch(errUser=>{
            log(errUser , 'err user')
            this.setState({ userExists : false })
          })
         })
      }else{
        log(randomID , 'err getting random number');
        alert("Error occured")
      }
    }

    render(){
        let { healthSystems , domain_id , userExists  } = this.state
        return(
            <div className='my-5 py-5 container'>
                <h4>Connect your Electronic Medical Records</h4>
                {userExists === true ? 
                  <div>
                      <p className='mb-0'>Please enter your city name</p>
                      <input name="emr_city" onChange={this.handleChange}></input>
                      <button style={{ display: 'block' }} onClick={()=>this.HealthSystems()} className='btn btn-sm btn-primary mt-3'>SEARCH</button>
                  </div>
                  :
                  <Loading />
                }
                <div>
                {healthSystems && healthSystems.length > 0
                  ? healthSystems.map((system) => (
                      <div
                        style={{
                          borderColor: "1px solid lightgrey",
                          backgroundColor: "#E5E5E5",
                        }}
                        key={system.id}
                        className="col-lg-8 row border mr-3 my-2"
                      >
                        <div className="col-lg-2 px-0">
                          {system && system.image_url === null ? (
                            <div
                              style={{ backgroundColor: "white" }}
                              className="d-flex align-items-center justify-content-start p-5"
                            >
                              <h5>{system.subtitle.charAt("0")}</h5>
                            </div>
                          ) : (
                            <img
                              style={{ height: 150, width: 150 }}
                              src={system.image_url}
                              alt="hs icon"
                            ></img>
                          )}
                        </div>
                        <div className="col-lg-8 px-0 mx-5">
                          <h6>{system.subtitle}</h6>
                          <button
                            className="btn btn-small btn-primary"
                            onClick={() => (window.location.href = system.url)} //this.openPopup(system.url)}
                          >
                            {system.title}
                          </button>
                        </div>
                      </div>
                    ))
                  : null}
                </div>
            </div>
        )
    }
}

export default ConnectEMR